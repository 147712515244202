<template>
  <v-app
      id="app" class="d-flex align-items-stretch h-100"
      v-bind:class="{ 'blur': this.$store.getters.authStatus === 'loading'}"
  >
    <router-view :key="this.$route.path"/>

    <VueTourWrapper/>
    <div v-bind:class="{ 'loading': this.$store.getters.authStatus === 'loading'}">
      <div class="centered" v-if="this.$store.getters.authStatus === 'loading'">
        <ProgressSpinner class="progress-indicator" strokeWidth="4"/>
      </div>
    </div>

  </v-app>
</template>

<style lang="scss">
@import './assets/scss/_variables.scss';
@import './assets/scss/_global.scss';
@import 'node_modules/bootstrap/scss/bootstrap.scss';

:root {
  --primary-color: $primary !important;
  --secondary-color: $secondary !important;
  --success-color: $success !important;
  --warning-color: $warning !important;
  --error-color: $danger !important;
}

#app {
  background: linear-gradient($dark, $light);
  color: $font-light;
}

.blur {
  filter: blur(2px);
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000 !important;
  width: 100vw !important;
  height: 100vh !important;
}
</style>
<script>


import VueTourWrapper from "@/components/VueTourWrapper.vue";

export default {
  components: {VueTourWrapper},
  created() {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout');
        }
        throw err;
      });
    });
  },

};
</script>
