<template>
  <div>
    <input class="datepicker-input " type="date" :value="this.$helper.formatDate(value, 'YYYY-MM-DD')"
           @input="updateValue"/>
  </div>

</template>

<style scoped lang="scss">

.datepicker-input {
  background: $secondary;
  border: 2px solid white;
  height: 40px;
  color: $text-primary;
  text-decoration: none;
  margin-top: 0;
  border-radius: 50px;
  padding-left: 1em;
  padding-right: 1em;
}

.datepicker-input::-webkit-calendar-picker-indicator {
  filter: invert(79%) sepia(37%) saturate(7500%) hue-rotate(6deg) brightness(92%) contrast(102%);
}

@media screen and (max-width: $break-medium) {
  .datepicker-input {
    width: 100%;
  }
}
</style>

<script>
export default {
  name: 'DatePicker',
  props: {
    value: {
      type: Date,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Datum auswählen',
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value);
    }
  }
}
</script>