<template>
  <PageLayout>
    <template #back-navigation>
      <BackLink to="backend.assessments.list" :label="$t('Assessments administration')"/>
    </template>
    <template #content-header>
      <template>{{ $t('assessment') }}</template>
    </template>
    <template #content-header-suffix>
      <template v-if="$route.params.id">{{ $t('edit') }}</template>
      <template v-if="!$route.params.id">{{ $t('add') }}</template>
      <template v-if="assessment.completedDate">
        {{ $t('completed at') + ' ' + $helper.formatDate(assessment.completedDate) }}
      </template>

    </template>
    <template #content>
      <div class="w-100 container">
        <form @submit.prevent="saveAssessment">
          <div class="row h-100">
            <div class="col-12 form-group" :class="{ 'form-group--error': $v.assessment.title.$error }">
              <label for="title">{{ $t('Title') }}</label>
              <InputText id="title" type="text" v-model="assessment.title"/>
            </div>
            <div class="error" v-if="!$v.assessment.title.required">Pflichtfeld</div>
            <div class="col-12 form-group" :class="{ 'form-group--error': $v.selectedAssessor.name.$error }">
              <label>{{ $t('Assessor') }}</label>
              <PickerInput id="assessor" @clearClick="clearAssessorSelected()"
                           :title="$t('Select assessor')"
                           :selected="selectedAssessor.name"
                           v-model="assessment.assessor"
                           :placeholder="$t('Select assessor')"
                           ref="assessor-picker"
                           :enabled="assessment.editable"
                            @dialogOpened="()=>{filters.global.value=null}">
                <DataTable :value="assessors"
                           :autoLayout="true"
                           :resizableColumns="true"
                           :filters="filters"
                           @row-click="assessorSelected"
                           :rowHover="true"
                           :paginator="true"
                           :rows="20"
                >
                  <template #empty>
                    {{ $t('No assessors found') }}
                  </template>
                  <template #header>
                    <div class="table-header d-flex align-items-center">
                      <span class="p-input-icon-left flex-grow-1">
                         <IconInput
                             v-model="filters['global'].value" :placeholder="$t('Search')"
                             type="text"
                             icon="pi pi-search"
                             :clear="true"
                         ></IconInput>
                      </span>
                      <div v-if="selectedAssessee.id" class="d-flex justify-content-center align-items-center">
                        <InputSwitch v-model="filterAssessorFunctionalGroup" class="mx-2"
                                     @change="filterAssessorFunctionalGroupChanged()"/>
                        <small class="filter-label">{{ $t('Filter assessee functional group') }}</small>
                      </div>
                    </div>
                  </template>
                  <Column field="name" :header="$t('name')" sortable>
                  </Column>
                  <Column field="email" :header="$t('Email')" sortable>
                  </Column>
                  <Column field="functional_groups_as_manager" :header="$t('Functional Groups as manager')" sortable>
                    <template #body="slotProps">
                      <span v-for="(functionalGroup, index) of slotProps.data.functional_groups_as_manager"
                            :key="functionalGroup.id">
                        {{ functionalGroup.name }}<span
                          v-if="index<slotProps.data.functional_groups_as_manager.length-1">,</span>
                      </span>
                    </template>
                  </Column>
                </DataTable>
              </PickerInput>

            </div>
            <div class="error" v-if="!$v.selectedAssessor.name.required">Pflichtfeld</div>

            <div class="col-12 form-group" :class="{ 'form-group--error': $v.selectedAssessee.name.$error }">
              <label>{{ $t('Assessee') }}</label>
              <PickerInput id="assessee" @clearClick="clearAssesseeSelected()"
                           :title="$t('Select assessee')"
                           :selected="selectedAssessee.name"
                           v-model="assessment.assessee"
                           :placeholder="$t('Select assessee')"
                           ref="assessee-picker"
                           :enabled="assessment.editable"
                          @dialogOpened="()=>{filters.global.value=null}"
              >
                <DataTable :value="assessees"
                           :autoLayout="true"
                           :resizableColumns="true"
                           :filters="filters"
                           @row-click="assesseeSelected"
                           :rowHover="true"
                           :paginator="true"
                           :rows="20"
                >
                  <template #empty>
                    {{ $t('No assessees found') }}
                  </template>
                  <template #header>
                    <div class="table-header d-flex align-items-center">
                      <span class="p-input-icon-left flex-grow-1">
                           <IconInput
                             v-model="filters['global'].value" :placeholder="$t('Search')"
                             type="text"
                             icon="pi pi-search"
                             :clear="true"
                         ></IconInput>
                      </span>
                      <div v-if="selectedAssessor.id" class="d-flex justify-content-center align-items-center">
                        <InputSwitch v-model="filterAssesseeFunctionalGroup" class="mx-2"
                                     @change="filterAssesseeFunctionalGroupChanged()"/>
                        <small class="filter-label">{{ $t('Filter assessor functional groups') }}</small>
                      </div>
                    </div>
                  </template>
                  <Column field="first_name" :header="$t('Firstname')" sortable>
                  </Column>
                  <Column field="last_name" :header="$t('Lastname')" sortable>
                  </Column>
                  <Column field="email" :header="$t('Email')" sortable>
                  </Column>
                  <Column field="functional_groups_as_member" :header="$t('Functional group')" sortable>
                    <template #body="slotProps">
                      <span v-for="(functionalGroup, index) of slotProps.data.functional_groups_as_member"
                            :key="functionalGroup.id">
                        {{ functionalGroup.name }} <span
                          v-if="index < slotProps.data.functional_groups_as_member.length-1">,</span>
                      </span>
                    </template>
                  </Column>
                </DataTable>
              </PickerInput>
            </div>
            <div class="error" v-if="!$v.selectedAssessee.name.required">Pflichtfeld</div>

            <div class="col-12 form-group" :class="{ 'form-group--error': $v.assessment.dueDate.$error }">
              <label>{{ $t('Due date') }}</label>
              <div class="d-block">
                <DatePicker v-model="assessment.dueDate"/>
              </div>
            </div>
            <div class="error" v-if="!$v.assessment.dueDate.required">Pflichtfeld</div>

            <div class="col-12 form-group" v-if="$route.params.id && jobPosition">
              <label>{{ $t('Job position') }}</label>
              <div class="d-flex flex-column flex-md-row align-items-center">
                <div class="p-inputtext col-md-8">{{ jobPosition.title }}</div>
                <SecondaryButton class="m-2" @click="confirmApplyCompetences"
                        type="button" v-if="assessment.editable">
                  {{ $t('Apply competences') }}
                </SecondaryButton>
              </div>
            </div>

            <div class="col-12 form-group" v-if="$route.params.id && futureJobPosition">
              <label>{{ $t('Future job position') }}</label>
              <div class="d-flex">
                <div class="p-inputtext p-component">{{ futureJobPosition.title }}</div>
              </div>
            </div>

            <div class="col-12 form-group" v-if="$route.params.id && userCompetences">
              <label>{{ $t('Competences') }}</label>
              <CustomPickList :data="userCompetences"
                              :column-field="'title'"
                              :prefix-field="'category_title'"
                              :color-indicator="'category_color'"
                              @move-to-target="addCompetence"
                              @move-to-source="removeCompetence"
                              ref="picklist"
                              :enabled="assessment.editable"
              ></CustomPickList>
            </div>
          </div>

          <portal to="footer-actions">
            <FormActions>
              <template #left>
                <DangerButton v-if="assessment.id" @click="confirmDelete">{{ $t('delete') }}</DangerButton>
              </template>
              <SecondaryButton v-if="$route.params.id" @click="confirmDuplicate"> {{
                  $t('Duplicate')
                }}
              </SecondaryButton>
              <PrimaryButton v-if="assessment.id" @click="saveAssessment">{{ $t('save') }}</PrimaryButton>
              <PrimaryButton v-if="!assessment.id" @click="saveAssessment">{{ $t('continue') }}</PrimaryButton>
            </FormActions>
          </portal>

        </form>


        <Dialog :visible.sync="showConfirmDelete" :style="{width: '350px'}" :modal="true">
          <template #header>
            {{ $t('Confirm Delete {title}', {title: assessment.title}) }}
          </template>
          <div class="confirmation-content">
            {{ $t('Do you really want to delete assessment {title}?', {title: assessment.title}) }}
          </div>
          <template #footer>
            <FormActions>
              <template #left>
                <SecondaryButton @click="confirmDelete"> {{ $t('Cancel') }}</SecondaryButton>
              </template>
              <DangerButton @click="deleteAssessment"> {{ $t('delete') }}</DangerButton>
            </FormActions>
          </template>
        </Dialog>
        <Dialog :visible.sync="showConfirmDuplicate" :style="{width: '350px'}" :modal="true">
          <template #header>
            {{ $t('Duplicate {title}', {title: assessment.title}) }}
          </template>
          <div class="confirmation-content">
            <form>
              <div class="col-12 form-group" :class="{ 'form-group--error': $v.duplicateTitle.$error }">
                <p>{{ $t('Enter title of duplicated assessment:') }}</p>
                <InputText id="duplicated_title" type="text" v-model="duplicateTitle"/>
              </div>
              <div class="error" v-if="!$v.duplicateTitle.required">{{ $t('Required field') }}</div>
            </form>
          </div>

          <template #footer>
            <FormActions>
              <template #left>
                <SecondaryButton @click="confirmDuplicate"> {{ $t('Cancel') }}</SecondaryButton>
              </template>
              <PrimaryButton @click="duplicateAssessment"> {{ $t('Duplicate') }}</PrimaryButton>
            </FormActions>
          </template>
        </Dialog>
        <Dialog :visible.sync="showConfirmApplyCompetences" :style="{width: '350px'}" :modal="true">
          <template #header>
            {{ $t('Apply competences from {title}?', {title: jobPosition.title}) }}
          </template>
          <div class="confirmation-content">
            <p>{{
                $t('Do you really want to apply the job position competences? All selected competences will be removed.')
              }}</p>
          </div>

          <template #footer>
            <FormActions>
              <template #left>
                <SecondaryButton @click="confirmApplyCompetences">{{ $t('Cancel') }}</SecondaryButton>
              </template>
              <PrimaryButton @click="applyCompetences"> {{ $t('Apply') }}</PrimaryButton>
            </FormActions>
          </template>
        </Dialog>
      </div>
    </template>
    <template #content-footer>
      <FormActions>
        <template #left>
          <DangerButton v-if="assessment.id" @click="confirmDelete">{{ $t('delete') }}</DangerButton>
        </template>
        <SecondaryButton v-if="$route.params.id" @click="confirmDuplicate"> {{ $t('Duplicate') }}</SecondaryButton>
        <PrimaryButton v-if="assessment.id" @click="saveAssessment">{{ $t('save') }}</PrimaryButton>
        <PrimaryButton v-if="!assessment.id" @click="saveAssessment">{{ $t('continue') }}</PrimaryButton>
      </FormActions>
    </template>
  </PageLayout>


</template>

<style lang="scss">
.custom-calendar .p-datepicker table td > span.p-highlight, .custom-calendar .p-datepicker table td.p-datepicker-today > span.p-highlight {
  background-color: $primary;
}

.filter-label {
  max-width: 90px;
}
</style>

<script>
import {required} from 'vuelidate/lib/validators';
import Dialog from 'primevue/dialog';
import PickerInput from "@/components/PickerInput";
import CustomPickList from "@/components/CustomPickList";
import PageLayout from "./PageLayout";
import {FilterMatchMode} from "primevue/api";
import IconInput from "../../components/IconInput.vue";
import BackLink from "../../components/BackLink.vue";
import DatePicker from "../../components/DatePicker.vue";

export default {
  name: 'assessments',
  data() {
    return {
      assessment: {
        id: null,
        title: null,
        dueDate: new Date(this.$moment()),
        assessee: {},
        assessor: {},
        editable: true
      },
      showConfirmDelete: false,
      showConfirmDuplicate: false,
      showConfirmApplyCompetences: false,
      duplicateTitle: null,
      selectedAssessee: {
        id: null,
        name: null
      },
      selectedAssessor: {
        id: null,
        name: null
      },
      jobPosition: null,
      futureJobPosition: null,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      userCompetences: null,
      assessees: null,
      assessors: null,
      filterAssesseeFunctionalGroup: true,
      filterAssessorFunctionalGroup: true,
    }
  },
  created() {
    this.getAssessment()
  },
  methods: {
    getAssessment() {
      if (this.$route.params.id) {
        this.$http.get('assessments/' + this.$route.params.id)
            .then((res) => {
              this.assessment = {...res.data}
              this.duplicateTitle = this.assessment.title
              this.assessment.dueDate = new Date(res.data.dueDate)
              this.selectedAssessee.id = res.data.assessee.id
              this.selectedAssessee.name = res.data.assessee.first_name + ' ' + res.data.assessee.last_name
              this.selectedAssessor.id = res.data.assessor.id
              this.selectedAssessor.name = res.data.assessor.first_name + ' ' + res.data.assessor.last_name
              this.getUser()
              this.getUserCompetences()
              this.getAssesseesForAssessor(this.selectedAssessor)
              this.getAssessorsForAssessee(this.selectedAssessee)
              if (!this.assessment.editable) {
                this.$toast.add({
                  severity: 'info',
                  summary: this.$t('Assessment is not editable, because competence rating started already.'),
                  life: 5000,
                });
              }
            }).catch((error) => {
          if (this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE, this.$roles.EXECUTIVE])) {
            this.$router.push({name: 'backend.assessments.assessment'})
          }
          console.log(error)
        })
      } else {
        this.getAssessees()
      }
    },
    getAssessees() {
      this.$store.dispatch('getAssessees').then(() => {
        this.assessees = this.$store.state.assessees

        this.getAssessors()
      })

    },
    getAssessors() {
      this.$store.dispatch('getAssessors').then(() => {
        this.assessors = this.$store.state.assessors
      })
    },
    getUserCompetences() {
      if (this.selectedAssessee) {
        this.$http.get('user-competences/' + this.assessment.assessee.id)
            .then((res) => {
              let competencesToRemove = this.assessment.competences.filter((competence) => {
                return !res.data.some((assessmentCompetence) => {
                  return competence.id === assessmentCompetence.id
                })
              })
              this.assessment.competences = res.data.filter((competence) => {
                return this.assessment.competences.some((assessmentCompetence) => {
                  return competence.id === assessmentCompetence.id
                })
              })

              let available = res.data.filter((competence) => {
                return !this.assessment.competences.some((assessmentCompetence) => {
                  return competence.id === assessmentCompetence.id
                })
              })
              for (let competence of competencesToRemove) {
                this.$http.delete('assessment-competence/' + this.assessment.id + '/' + competence.id)
                    .catch((error) => {
                      this.$toast.add({
                        severity: 'error',
                        summary: this.$t(error.message),
                        life: 3000,
                      });
                    });
              }

              this.userCompetences = [available, this.assessment.competences]
            })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t('Error getting user competences'),
                detail: error.message,
                life: 3000,
              });
            })
      }
    },
    getUser() {
      if (this.selectedAssessee) {
        this.$http.get('users/' + this.assessment.assessee.id)
            .then((res) => {
              this.jobPosition = res.data.job_position
              this.futureJobPosition = res.data.future_job_position
            })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t('Error getting user competences'),
                detail: error.message,
                life: 3000,
              });
            })
      }
    }
    ,
    saveAssessment() {
      this.$v.assessment.$touch();
      this.$v.selectedAssessor.name.$touch();
      this.$v.selectedAssessee.name.$touch();

      if (
          this.$v.assessment.$invalid ||
          this.$v.selectedAssessee.name.$invalid ||
          this.$v.selectedAssessor.name.$invalid
      ) {
        console.log("INVALID", this.$v)
        this.submitStatus = 'ERROR';
        this.$toast.add({
          severity: 'error',
          summary: this.$t('The form has errors'),
          detail: this.$t('Please check the form again'),
          life: 3000,
        });
      } else {
        if (this.$route.params.id) {
          this.$http.put('assessments/' + this.assessment.id,
              {
                'title': this.assessment.title,
                'assessorId': this.assessment.assessor.id,
                'assesseeId': this.assessment.assessee.id,
                'dueDate': this.$moment(this.assessment.dueDate).format('YYYY-MM-DD')
              })
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Assessment updated successfully'),
                  life: 3000,
                });
              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in updating assessments'),
                  detail: error.message,
                  life: 3000,
                });
              });
        } else {
          console.log("posting", this.assessment.assessee)
          this.$http.post('assessments', {
            'title': this.assessment.title,
            'assesseeId': this.assessment.assessee.id,
            'assessorId': this.assessment.assessor.id,
            'dueDate': this.$moment(this.assessment.dueDate).format('YYYY-MM-DD')
          })
              .then((res) => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Assessment added successfully'),
                  life: 3000,
                });
                console.log("Added", res.data)
                this.$store.dispatch('getAssessments')
                if (this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE, this.$roles.EXECUTIVE])) {
                  this.$router.push('/backend/assessments/assessment/' + res.data.id)
                }
              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in adding assessment'),
                  detail: error.message,
                  life: 3000,
                });
              });
        }
      }
    },
    confirmDelete() {
      this.showConfirmDelete = !this.showConfirmDelete
    },
    confirmDuplicate() {
      this.showConfirmDuplicate = !this.showConfirmDuplicate
    },
    confirmApplyCompetences() {
      this.showConfirmApplyCompetences = !this.showConfirmApplyCompetences
    },
    applyCompetences() {
      this.$store.commit('setLoading', 'loading');
      this.$http.post('assessments/' + this.assessment.id + '/apply-job-position-competences/' + this.jobPosition.id)
          .then((res) => {
            this.assessment.competences = res.data.competences
            this.confirmApplyCompetences()
            this.getUserCompetences()
            this.$store.commit('setLoading', 'success');
            this.$toast.add({
              severity: 'success',
              summary: this.$t('Competences successfully applied'),
              life: 3000,
            });
          }).catch((error) => {
        this.$store.commit('setLoading', 'success');
        this.showConfirmDuplicate = false;
        this.$toast.add({
          severity: 'error',
          summary: this.$t(error.data.message),
          life: 3000,
        });
      });
    },
    deleteAssessment() {
      this.$http.delete('assessments/' + this.assessment.id)
          .then(() => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'success',
              summary: this.$t('Assessment successfully deleted'),
              life: 3000,
            });
            if (this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE, this.$roles.EXECUTIVE])) {
              this.$router.push('/backend/assessments')
            }

          })
          .catch((error) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'error',
              summary: this.$t(error.data.message),
              life: 3000,
            });
          });
    },
    async addCompetence(competence) {
      console.log("Items ", competence.items)
      this.$store.commit('setLoading', 'loading');
      for (let item of competence.items) {
        await this.$http.post('assessment-competence', {'assessmentId': this.assessment.id, 'competenceId': item.id})
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.message),
                life: 3000,
              });
            });
      }
      this.$store.commit('setLoading', 'success');
      this.$toast.add({
        severity: 'success',
        summary: this.$tc('Competence successfully added', competence.items.length),
        life: 3000,
      });
    },
    async removeCompetence(competence) {
      console.log("remove")
      this.$store.commit('setLoading', 'loading');
      for (let item of competence.items) {
        await this.$http.delete('assessment-competence/' + this.assessment.id + '/' + item.id)
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.message),
                life: 3000,
              });
            });
      }
      this.$store.commit('setLoading', 'success');
      this.$toast.add({
        severity: 'success',
        summary: this.$tc('Competence successfully removed', competence.items.length),
        life: 3000,
      });

    },
    duplicateAssessment() {
      this.$v.duplicateTitle.$touch();
      if (this.$v.duplicateTitle.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$http.post('assessments/' + this.assessment.id + '/duplicate', {
          'title': this.duplicateTitle
        })
            .then((res) => {
              this.showConfirmDuplicate = false;
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Assessment successfully duplicated'),
                life: 3000,
              });
              if (this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE, this.$roles.EXECUTIVE])) {
                this.$router.push('/backend/assessments/assessment/' + res.data.id)
              }
            })
            .catch((error) => {
              this.showConfirmDuplicate = false;
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.data.message),
                life: 3000,
              });
            });
      }
    },
    clearAssessorSelected() {
      this.selectedAssessor.id = null
      this.selectedAssessor.name = null
      this.assessment.assessor = null
      this.getAssessees()
    },
    clearAssesseeSelected() {
      this.selectedAssessee.id = null
      this.selectedAssessee.name = null
      this.assessment.assessee = null
      for (let competence of this.userCompetences[1]) {
        this.$http.delete('assessment-competence/' + this.assessment.id + '/' + competence.id)
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.message),
                life: 3000,
              });
            });
      }
      this.userCompetences = [[], []]
      this.assessment.competences = []
      this.getAssessors()
    },
    assesseeSelected(assessee) {
      this.selectedAssessee.id = assessee.data.id
      this.selectedAssessee.name = assessee.data.first_name + ' ' + assessee.data.last_name
      this.assessment.assessee = assessee.data
      if (this.$route.params.id) {
        this.getUser()
        this.getUserCompetences()
      }
      this.getAssessorsForAssessee(assessee.data)
      this.$refs["assessee-picker"].closeDialog()
    },
    getAssessorsForAssessee(assessee) {
      this.$http.get("/assessors-for-assessee/" + assessee.id)
          .then((res) => {
            this.assessors = res.data;
          })
    },
    assessorSelected(assessor) {
      this.selectedAssessor.id = assessor.data.id
      this.selectedAssessor.name = assessor.data.first_name + ' ' + assessor.data.last_name
      this.assessment.assessor = assessor.data
      this.getAssesseesForAssessor(assessor.data)
      this.$refs["assessor-picker"].closeDialog()
    },
    getAssesseesForAssessor(assessor) {
      this.$http.get("/assessees-for-assessor/" + assessor.id)
          .then((res) => {
            this.assessees = res.data
          })
    },
    filterAssessorFunctionalGroupChanged() {
      this.$store.dispatch('getAssessors').then(() => {
        if (!this.filterAssessorFunctionalGroup) {
          this.assessors = this.$store.state.assessors
        } else {

          if (this.selectedAssessee.id) {
            this.getAssessorsForAssessee(this.selectedAssessee)
          }
        }
      })

    },
    filterAssesseeFunctionalGroupChanged() {
      this.$store.dispatch('getAssessees').then(() => {
        if (!this.filterAssesseeFunctionalGroup) {
          this.assessees = this.$store.state.assessees
        } else {
          if (this.selectedAssessor.id) {
            this.getAssesseesForAssessor(this.selectedAssessor)
          }
        }
      })

    }
  },
  components: {
    DatePicker,
    BackLink,
    IconInput,
    Dialog,
    PickerInput,
    CustomPickList,
    PageLayout,
  },
  validations: {
    assessment: {
      title: {
        required,
      },
      dueDate: {
        required
      },
    },
    duplicateTitle: {
      required
    },
    selectedAssessee: {
      name: {
        required
      }
    },
    selectedAssessor: {
      name: {
        required
      }
    },

  },
  watch: {
    userCompetences: {
      deep: true,
      handler() {
        if (this.$refs.picklist) {
          this.$refs.picklist.refresh(this.userCompetences)
        }
      }
    }
  }
}
</script>