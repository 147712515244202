<template>
  <div class="d-flex justify-center">
    <div class="Slider mt-4 pl-5 pr-5 mt-5 sliders" style="margin-bottom:50px">
      <div class="prepend-line single" style="margin-top:26px"></div>
      <v-slider
          class="custom-slider public-slider-fill"
          v-model="fillValue"
          min="1"
          max="8"
          steps="6"
          color="#dc8e00"
          track-color="#C3C8CC"
      >
      </v-slider>
      <v-slider
          class="custom-slider public-slider mt-3"
          v-model="value"
          min="0"
          max="8"
          steps="7"
          :ticks="(user.show_ticks)?'always':false"
          color="transparent"
          track-color="transparent"
          thumb-size="20"
          thumb-label="always"
          @input="updateValue"
          @end="emitEnd"
      >
        <template v-slot:thumb-label>
          <slot name="thumb-label"></slot>
        </template>
        <template v-slot:prepend>
          <div class="prepend-indicator">
            <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
              <polygon points="50 0, 65 200, 35 200"/>
            </svg>
            <span class="label">{{ $t('novice') }}</span>
          </div>
        </template>
        <template v-slot:append>
          <div class="append-indicator">
            <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
              <polygon points="50 0, 65 200, 35 200"/>
            </svg>
            <span class="label">{{ $t('expert') }}</span>
          </div>
        </template>
      </v-slider>
    </div>
  </div>

</template>

<script>
export default {
  name: "AbilitySlider",
  data() {
    return {
      user: this.$store.getters.user,
      value: 0,
      fillValue: 0,
    }
  },
  created() {
    this.value = this.abilityValue
  },
  props: {
    abilityValue: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    updateValue(newValue) {
      this.fillValue = newValue;
      this.$emit('input', newValue);
    },
    emitEnd(newValue) {
      this.$emit('end', newValue);
    }
  },
  watch: {
    value: function () {
      this.fillValue = this.value
    },
    abilityValue: function () {
      this.value = this.abilityValue
    }
  }

}
</script>

<style lang="scss">

</style>