import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '../views/Login.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Activate from '../views/Activate.vue';

import store from '../store';
import appRoutes from "@/router/appRoutes";


Vue.use(VueRouter);

const baseRoutes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/logout',
        name: 'logout',
        component: Login,
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: ResetPassword,
    },
    {
        path: '/activate/:id/:hash',
        name: 'activate',
        component: Activate,
    },
];
const routes = baseRoutes.concat(appRoutes);
const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    let baseRouteNames = baseRoutes.map((route)=>route.name)
    if (!store.getters.isLoggedIn && !baseRouteNames.includes(to.name)) {
        return next('/login');
    }
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    if (to.matched.some(record => record.meta.type === 'backend')) {

        if (store.getters.isLoggedIn && Vue.prototype.$helper.hasAnyRole([
            Vue.prototype.$roles.ADMIN,
            Vue.prototype.$roles.HRADMIN,
            Vue.prototype.$roles.HRMANAGER,
            Vue.prototype.$roles.HREMPLOYEE,
            Vue.prototype.$roles.EXECUTIVE])) {
            next();
            return;
        }
        next('/login');
    } else if (to.matched.some(record => record.meta.type === 'frontend')) {

        if (store.getters.isLoggedIn && Vue.prototype.$helper.hasAnyRole(Vue.prototype.$roles.EMPLOYEE)) {
            next();
            return;
        }
        next('/login');
    } else {
        next()
    }
});

export default router;
