import Home from "@/views/frontend/Home";
import Competences from "@/views/frontend/Competences";
import CompetenceProcess from "@/views/frontend/CompetenceProcess";
import SelfassessmentProcess from "@/views/frontend/SelfassessmentProcess";
import CompetenceDetail from "@/views/frontend/CompetenceDetail";
import SelfassessmentSummary from "@/views/frontend/SelfassessmentSummary";
import MyTeamList from "@/views/frontend/MyTeamList";
import AssessmentList from "@/views/frontend/AssessmentList";
import AssessmentHome from "@/views/frontend/AssessmentHome";
import AssessmentSummary from "@/views/frontend/AssessmentSummary";
import AssessmentDetail from "@/views/frontend/AssessmentDetail";
import AssessmentProcess from "@/views/frontend/AssessmentProcess";
import PublicassessmentProcess from "@/views/frontend/PublicassessmentProcess";
import Messages from "@/views/frontend/Messages";
import MessageDetail from '../views/frontend/MessageDetail.vue';
import Profile from "@/views/frontend/Profile";
import AssessmentIntro from "@/views/frontend/AssessmentIntro";
import AssessmentOutro from "@/views/frontend/AssessmentOutro";
import AssessmentReview from "../views/frontend/AssessmentReview";
import AssessmentCompetence from "../views/frontend/AssessmentCompetence";
import PublicAssessmentHome from "../views/frontend/PublicAssessmentHome.vue";
import SelfAssessmentHome from "../views/frontend/SelfAssessmentHome.vue";
import AssessmentFinish from "@/views/frontend/AssessmentFinish.vue";

export default [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: 'Talenteers',
        },
    },
    {
        path: '/competences',
        name: 'competences',
        component: Competences,
        meta: {
            title: 'Kompetenzen',
        },
    },
    {
        path: '/competence-process/:stepId',
        name: 'competence-process',
        component: CompetenceProcess,
        meta: {
            title: 'Selbstbild',
        },
    },
    {
        path: '/selfassessment-process/:stepId/:assessmentId',
        name: 'selfassessment-process',
        component: SelfassessmentProcess,
        meta: {
            title: 'Selbstbild',
        },
    },
    {
        path: '/competence-detail/:competenceId',
        name: 'competence-detail',
        component: CompetenceDetail,
        meta: {
            title: 'Selbstbild',
        },
    },
    {
        path: '/selfassessment-summary',
        name: 'selfassessment-summary',
        component: SelfassessmentSummary,
        meta: {
            title: 'Selbstbild',
        },
    },
    {
        path: '/my-team-list',
        name: 'my-team-list',
        component: MyTeamList,
        meta: {
            title: 'Personen',
        },
    },
    {
        path: '/assessment-list',
        name: 'assessment-list',
        component: AssessmentList,
        meta: {
            title: 'Assessments',
        },
    },
    {
        path: '/assessment-intro/:assessmentId/:assesseeId',
        name: 'assessment-intro',
        component: AssessmentIntro,
        meta: {
            title: 'Assessment',
        },
    },
    {
        path: '/assessment-outro/:assessmentId/:assesseeId',
        name: 'assessment-outro',
        component: AssessmentOutro,
        meta: {
            title: 'Assessment',
        },
    },
    {
        path: '/assessment-home/:assessmentId/:assesseeId',
        name: 'assessment-home',
        component: AssessmentHome,
        meta: {
            title: 'Assessment',
        },
    },
    {
        path: '/assessment-finish/:assessmentId/:assesseeId',
        name: 'assessment-finish',
        component: AssessmentFinish,
        meta: {
            title: 'Assessment Abschluss',
        },
    },
    {
        path: '/public-assessment-home/:assessmentId',
        name: 'public-assessment-home',
        component: PublicAssessmentHome,
        meta: {
            title: 'Public Assessment',
        },
    },
    {
        path: '/self-assessment-home/:assessmentId',
        name: 'self-assessment-home',
        component: SelfAssessmentHome,
        meta: {
            title: 'Self Assessment',
        },
    },
    {
        path: '/assessment-review/:assessmentId/:assesseeId',
        name: 'assessment-review',
        component: AssessmentReview,
        meta: {
            title: 'Assessment Review',
        },
    },
    {
        path: '/assessment-summary/:assessmentId/:assesseeId',
        name: 'assessment-summary',
        component: AssessmentSummary,
        meta: {
            title: 'Assessment Summary',
        },
    },
    {
        path: '/assessment-detail/:assesseeId/:competenceId',
        name: 'assessment-detail',
        component: AssessmentDetail,
        meta: {
            title: 'Assessment',
        },
    },
    {
        path: '/assessment-process/:stepId/:assessmentId/:assesseeId',
        name: 'assessment-process',
        component: AssessmentProcess,
        meta: {
            title: 'Assessment',
        },
    },
    {
        path: '/assessment-competence/:stepId/:assessmentId/:assesseeId',
        name: 'assessment-competence',
        component: AssessmentCompetence,
        meta: {
            title: 'Assessment Competence',
        },
    },
    {
        path: '/publicassessment-process/:stepId/:assessmentId',
        name: 'publicassessment-process',
        component: PublicassessmentProcess,
        meta: {
            title: 'Abgestimmtes Bild',
        },
    },
    {
        path: '/messages',
        name: 'messages',
        component: Messages,
        meta: {
            title: 'Nachrichten',
        },
    },
    {
        path: '/messages/:messageId',
        name: 'message-detail',
        component: MessageDetail,
        meta: {
            title: 'Nachricht',
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: 'Profil',
        },
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    },
]