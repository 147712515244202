<template>
  <div class="container d-flex flex-column">
    <h4 class="text-white text-center">{{ competence.title }}</h4>
    <div class="pt-1 competence-title-description">= {{ competence.description }}</div>
    <div class="question-title text-center mb-9 mt-3">{{ $t('ability') }}</div>
    <AbilitySlider v-model="abilityValue" :ability-value="abilityValue">
      <template v-slot:thumb-label>
        <img class="publicassessment-tooltip-icon" src="@/assets/icons/k.svg"/>
      </template>
    </AbilitySlider>

    <div class="mt-10" v-if="competence.competence_levels">
      <div v-if="active === 'ability'">
        <span v-if="abilityValue <= 0">
          <div class="competence-title-container">
            <span class="competence-title">{{ $t('ability') }}</span>
          </div>
        </span>
        <div class='competence-title-container' v-if="abilityValue>0">
              <span class='competence-title'>{{
                  $t('competences.levelTitles.level' + (abilityValue) + '.title')
                }}</span>
          <span
              class='competence-title-suffix'> {{
              $t('competences.levelTitles.level' + (abilityValue) + '.suffix')
            }}</span>
        </div>
      </div>
    </div>
    <div
        class="overflow-auto text-container competence-level-description pt-0"
        v-if="competence.competence_levels"
    >
      <span
          v-if="abilityValue > 0 && active === 'ability'" v-html="getCompetenceLevelDescription(abilityValue)"
      ></span>
      <span
          v-if="abilityValue <= 0 && active === 'ability'"
      >{{ competence.competence_levels[0].description }}</span>
    </div>
    <div class="flex-grow-1">
    </div>
    <button
        :class="{'disabled': abilityValue < 1}"
        class="btn btn-primary btn-block text-uppercase rounded-pill center-button"
        @click="saveClicked"
    >{{ $t('save') }}
    </button>

  </div>
</template>

<script>


import AbilitySlider from "../../components/AbilitySlider.vue";

export default {
  name: 'job-roles-competence',
  components: {AbilitySlider},
  data() {
    return {
      jobRole: {
        id: null,
        title: null,
      },
      competence: {
        id: null,
        title: null,
        description: null,
      },
      abilityValue: 0,
      abilityFillValue: 1,
      active: 'ability',
      abilityActive: true,
    }
  },
  created() {
    if (localStorage.getItem('selectedJobRole')) {
      let selectedJobRole = JSON.parse(localStorage.getItem('selectedJobRole'))
      this.jobRole = {...selectedJobRole}
    }
    this.$http.get('competences/' + this.$route.params.id).then((res) => {
      this.competence = res.data
      this.competence.competence_levels.unshift(
          {
            title: 'Können',
            description: this.$t('Please move the slider to set the requirement of the role for the competence'),
          },
      )
      if (localStorage.getItem('jobRoleAbility')) {
        let competenceValue = JSON.parse(localStorage.getItem('jobRoleAbility'))
        this.abilityValue = competenceValue
        this.abilityFillValue = competenceValue
      }
    })


  },
  methods: {
    saveClicked() {
      if (this.jobRole.competences.filter((competence) => {
        return competence.id === this.competence.id
      }).length > 0) {
        this.$http.put('job-role-competence', {
          'competenceId': this.competence.id,
          'jobRoleId': this.jobRole.id,
          'jobAbility': this.abilityValue,
        }).then(() => {
          this.$toast.add({
            severity: 'success',
            summary: this.$t('Competence level successfully updated'),
            life: 3000,
          });
          this.$router.push({
            name: 'backend.job-role-groups.job-role',
            params: {id: this.jobRole.id, compareJobRole: this.$route.params.compareJobRole}
          });
        })
            .catch((error) => {
              this.showConfirmRemove = false;
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.data.message),
                life: 3000,
              });
            });
      } else {
        this.$http.post('job-role-competence', {
          'competenceId': this.competence.id,
          'jobRoleId': this.jobRole.id,
          'jobAbility': this.abilityValue,
        }).then(() => {
          this.$toast.add({
            severity: 'success',
            summary: this.$t('Competence added successfully'),
            life: 3000,
          });
          this.$router.push({
            name: 'backend.job-role-groups.job-role',
            params: {id: this.jobRole.id, compareJobRole: this.$route.params.compareJobRole}
          });
        })
            .catch((error) => {
              this.showConfirmRemove = false;
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.data.message),
                life: 3000,
              });
            });
      }
    },
    getCompetenceLevelDescription(value) {
      console.log(this.competence.competence_levels)
      let compLevel = this.competence.competence_levels.find((competenceLevel) => {
        return competenceLevel.level === value
      });
      return compLevel.description;
    }
  },
}
</script>