<template>
      <div class="text-center mx-auto" v-if="data">
        <template v-if="data.completed_date">
                  <span
                      class="text-uppercase rounded-pill px-2 py-1 text-white border border-success font-weight-bold text-success">
                    {{ $t('completed') }}
                  </span>
        </template>
        <template v-if="(data.state === 'review_assessment' && !data.completed_date)">
                  <span class="text-uppercase rounded-pill px-2 py-1 border font-weight-bold">
                    {{ $t('open') }}
                  </span>
        </template>
        <template v-if="(data.state === 'open_assessment' && !data.completed_date)">
                  <span class="text-uppercase rounded-pill px-2 py-1 border  text-white font-weight-bold">
                    {{ $t('open') }}
                  </span>
        </template>
      </div>
</template>
<script>

export default {
  name: 'assessment-state-column',
  props:['data']
}

</script>