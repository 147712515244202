<template>

  <PageLayout>
    <template #back-navigation>
      <BackLink to="assessment-home" :params="{assessmentId:assessment.id}" :label="$t('toSummary')"/>
    </template>
    <template #content-header>
      {{ $t('assessment') }}
    </template>
    <template #content-header-suffix v-if="currentCompetence">
      <div class="d-flex align-items-center">
        <div class="px-2" :style="{'border-left': '2px solid #'+currentCompetence.competence_category.color}">
          <div>
            {{ currentCompetence.title }}
          </div>
          <div class="d-flex justify-content-center">
          <span class="font-weight-lighter" style="font-size:10pt;">Kompetenz {{ currentStep + 1 }} von {{
              assessment.competences.length
            }}</span>
          </div>
        </div>
        <div>
          <i v-if="actionRequired"
             class="pi pi-flag-fill competence-flag flagged"></i>
        </div>

      </div>
    </template>
    <template #content>
      <div class="container pt-0" v-if="assessment">
        <div class="pt-0 competence-title-description mb-4">= {{ currentCompetence.description }}</div>

        <p class="question-title text-center mb-5 text-uppercase mt-9">{{ $t('commonAssessment.currentQuestion') }}</p>
        <div class="Slider container px-0 px-sm-5 sliders">
          <div class="prepend-line single"></div>
          <v-slider
              :disabled="true"
              class="custom-slider self-claim-slider inactive"
              v-model="claimAbilityValue"
              min="0"
              max="8"
              steps="7"
              color="transparent"
              thumb-size="20"
              thumb-color="transparent"
              v-if="claimAbilityValue>0"
          >
            <template v-slot:prepend>
              <div class="prepend-indicator">
                <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="50 0, 65 200, 35 200"/>
                </svg>
                <span class="label">{{ $t('novice') }}</span>
              </div>
            </template>
            <template v-slot:append>
              <div class="append-indicator">
                <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="50 0, 65 200, 35 200"/>
                </svg>
                <span class="label">{{ $t('expert') }}</span>
              </div>
            </template>
          </v-slider>

          <template v-if="assessment.state === 'open_assessment'">
            <v-slider
                class="custom-slider public-slider-fill"
                v-model="commonFillValue"
                min="1"
                max="8"
                steps="6"
                color="#dc8e00"
                track-color="#C3C8CC"
            ></v-slider>
            <v-slider
                class="custom-slider common-slider"
                v-model="commonValue"
                min="0"
                max="8"
                @start="commonDragStart"
                @end="commonDragEnd"
                steps="7"
                :ticks="(user.show_ticks)?'always':false"
                color="transparent"
                track-color="transparent"
                thumb-size="20"
            ></v-slider>
          </template>
          <template v-if="assessment.state === 'review_assessment'">
            <v-slider
                class="custom-slider ability-slider"
                v-model="commonValue"
                min="0"
                max="8"
                steps="7"
                :ticks="(user.show_ticks)?'always':false"
                color="transparent"
                track-color="transparent"
                thumb-size="60"
                thumb-label="always"
                thumb-color="transparent"
            >
              <template v-slot:thumb-label>
                <img class="selfassessment-tooltip-icon mt-3 h-5 w-5" src="@/assets/icons/i.svg"/>
              </template>
            </v-slider>
            <v-slider
                class="custom-slider public-slider-fill"
                :readonly="true"
                :value="assessment.competences[currentStep].common_ability"
                min="1"
                max="8"
                steps="6"
                color="#dc8e00"
                track-color="#C3C8CC"
                thumb-size="1"
            ></v-slider>
            <v-slider
                :disabled="true"
                class="custom-slider common-slider inactive"
                :value="assessment.competences[currentStep].common_ability"
                min="0"
                max="8"
                steps="7"
                color="transparent"
                track-color="transparent"
                thumb-size="20"
            ></v-slider>
          </template>

          <v-slider
              :disabled="true"
              class="custom-slider self-slider inactive"
              v-model="selfAbilityValue"
              min="0"
              max="8"
              steps="7"
              color="transparent"
              thumb-size="20"
              thumb-color="transparent"
              v-if="selfAbilityValue>0"
          >
            <template v-slot:thumb-label>
              <img class="selfassessment-tooltip-icon" src="@/assets/icons/koennen-large.svg" alt="koennen-large"/>
            </template>
          </v-slider>
          <v-slider
              :disabled="true"
              class="custom-slider public-slider inactive"
              v-model="publicAbilityValue"
              min="0"
              max="8"
              steps="7"
              color="transparent"
              thumb-size="20"
              thumb-color="transparent"
              v-if="publicAbilityValue>0"

          ></v-slider>
        </div>
        <div class="mt-15 mt-sm-10 container" v-if="currentCompetence.competence_levels">
          <div v-if="commonValue <= 0">
            <div class="competence-title-container">
              <span class="competence-title text-uppercase">{{ $t('ability') }}</span>
              <span
                  class='competence-title-suffix'> {{ $t('Rating') }}</span>
            </div>
          </div>
          <div class='competence-title-container' v-if="commonValue>0">
            <span class='competence-title'>{{ $t('competences.levelTitles.level' + (commonValue) + '.title') }}</span>
            <span
                class='competence-title-suffix'> {{
                $t('competences.levelTitles.level' + (commonValue) + '.suffix')
              }}</span>
          </div>
        </div>
        <div
            class="overflow-auto container text-container"
            v-if="currentCompetence.competence_levels"
        >
      <span
          v-if="commonValue > 0"
          class="competence-level-description" v-html="getCompetenceLevelDescription(commonValue)"
      ></span>
          <span
              class="competence-level-description"
              v-if="commonValue <= 0"
          >{{ currentCompetence.competence_levels[0].description }}
      </span>
        </div>
        <portal to="dialog">
          <Dialog :visible.sync="showAssessmentComment" :modal="true" :maximizable="true" :dismissableMask="true">
            <template #header>
              <div class="mx-auto">
                <span class="font-weight-bolder">{{ $t('Comment') }} </span>
                <span class="font-weight-lighter">{{ currentCompetence.title }}</span>
              </div>
            </template>
            <div>
              <Textarea v-model="comment" :autoResize="true"></Textarea>
            </div>

            <template #footer>
              <FormActions>
                <template #left>
                  <SecondaryButton @click="()=>showAssessmentComment=false"> {{ $t('Cancel') }}</SecondaryButton>
                </template>
                <div class="d-flex align-items-center justify-center">
                  <div class="d-flex align-items-center justify-content-center">
                    {{ $t('I want to improve this competence') }}
                    <span class="mx-2  d-flex">
                    <InputSwitch v-model="actionRequired"></InputSwitch>
                  </span>
                  </div>
                  <PrimaryButton @click="updateAssessmentCompetence"> {{ $t('save') }}</PrimaryButton>
                </div>

              </FormActions>
            </template>
          </Dialog>
        </portal>
      </div>
    </template>
    <template #content-footer>
      <div>
        <SecondaryButton v-if="currentStep>0"
                         @click="backClicked"
        >{{ $t('back') }}
        </SecondaryButton>
        <RouterLink v-else :to="{name: 'assessment-home', params:{assessmentId:assessment.id}}">
          <SecondaryButton
          >{{ $t('toSummary') }}
          </SecondaryButton>
        </RouterLink>
      </div>
      <div class="flex-grow-1 justify-content-center align-items-center text-center competence-progress mx-5 my-2">
        <div class="d-flex align-items-center justify-center">
          <div class="d-flex align-items-center justify-content-center flex-grow-1 ">
            {{ $t('I want to improve this competence') }} <span class="mx-2"><InputSwitch v-model="actionRequired"
                                                                                          @change="updateAssessmentCompetence"></InputSwitch></span>
          </div>

        </div>
        <div class="d-flex flex-column align-items-center justify-center">
          <SecondaryButton @click="()=>{showAssessmentComment=true}">
            <span> {{ $t('Comment') }} / {{ $t('learn') }}</span>
          </SecondaryButton>
        </div>
        <ProgressBar :value="completedPercent">{{ completedPercent }}%</ProgressBar>
      </div>
      <div>
        <PrimaryButton
            @click="continueClicked"
        >{{ $t('continue') }}
        </PrimaryButton>
      </div>
    </template>
  </PageLayout>


</template>
<style lang="scss">
.bottom-col {
  min-width: 33%;
}

.assessment-detail .public-slider .v-slider__track-container,
.assessment-detail .self-slider .v-slider__track-container,
.assessment-detail .self-claim-slider .v-slider__track-container {
  display: none !important;
}

.competence-title-description {
  color: $font-light;
}

.p-slider-horizontal,
.p-inputtext {
  width: 100%;
}

.p-slider-vertical {
  height: 14em;
}

.page-counter {
  position: absolute;
  right: 1em;
  bottom: 1em;
}

.custom-dot {
  width: 100%;
  height: 100%;
}

.custom-mark-1 {
  position: absolute;
  top: 10px;
  white-space: nowrap;
  color: $gray;
  text-transform: uppercase;
}

.custom-mark-2 {
  position: absolute;
  top: 10px;
  transform: translateX(-100%);
  white-space: nowrap;
  color: $gray;
  text-transform: uppercase;
}

.description-content {
  max-height: 40vh;
  overflow: auto;
}

.navigation-buttons {
  flex-direction: row !important;
}

@media screen and (max-width: $break-medium) {
  .navigation-buttons {
    flex-direction: column !important;

  }
}

</style>
<script>
import PageLayout from "../backend/PageLayout.vue";
import BackLink from "../../components/BackLink.vue";

export default {
  name: 'assessment-process',
  components: {BackLink, PageLayout},
  data() {
    return {
      assessment: null,
      user: this.$store.getters.user,
      employee: null,
      competenceId: null,
      currentCompetence: {},
      data: [],
      currentStep: Number(this.$route.params.stepId),
      publicAbilityValue: 0,
      selfAbilityValue: 0,
      claimAbilityValue: 0,
      commonValue: 0,
      commonFillValue: 0,
      active: 'ability',
      claimActive: false,
      abilityActive: false,
      actionRequired: false,
      showAssessmentComment: false,
      comment: null,
    };
  },
  created: function () {
    this.getAssessment();
  },
  methods: {
    getAssessment() {
      this.$http.get('/assessmentCompetences/' + this.$route.params.assessmentId)
          .then((res) => {
            this.assessment = res.data;
            this.assessee = res.data.assessee;
            if (this.currentStep < this.assessment.competences.length) {
              this.getCompetence();
            }
          })
    },
    getCompetence() {
      this.$store.commit('setLoading', 'loading');
      this.competenceId = this.assessment.competences[
          this.currentStep
          ].id;
      this.$http.get('/competences/' + this.competenceId).then(res => {
        this.currentCompetence = res.data;
        this.data = this.currentCompetence.competence_levels.map(res => {
          return res.level - 1;
        });
        this.data.push(this.data.length);
        this.currentCompetence.competence_levels.unshift({
          description:
              this.$t('commonAssessment.setCommonAbilityInfo'),
        });
        this.publicAbilityValue = this.assessment.competences[
            this.currentStep
            ].public_ability;
        this.commonValue = this.assessment.competences[this.currentStep].common_ability;
        this.actionRequired = this.assessment.competences[this.currentStep].action_required;
        this.comment = this.assessment.competences[this.currentStep].comment;

        this.$store.dispatch('getEmployee', {id: this.assessment.assessee.id}).then((res) => {
          this.employee = res.data
          let employeeCompetence = this.employee.competences.find((competence) => {
            return competence.id === this.currentCompetence.id
          })
          this.selfAbilityValue = employeeCompetence.self_ability
          this.claimAbilityValue = employeeCompetence.self_claim
          if ((this.publicAbilityValue === this.selfAbilityValue) && this.commonValue === 0) {
            this.commonValue = this.publicAbilityValue
            this.assessment.competences[this.currentStep].common_ability = this.commonValue
            this.updateAssessmentCompetence()
          }
        })


        this.$store.commit('setLoading', 'success');
      });


    },
    commonDragStart() {
      this.claimActive = true;
      this.abilityActive = false;
      this.active = 'claim';
    },
    commonDragEnd() {
      this.$store
          .dispatch('postAssessmentCompetence', {
            assessmentId: this.$route.params.assessmentId,
            competenceId: this.currentCompetence.id,
            commonAbility: this.commonValue,
          })
          .then(() => {
            this.assessment.competences[this.currentStep].common_ability = this.commonValue
          })
    },
    updateAssessmentCompetence() {
      this.$store
          .dispatch('postAssessmentCompetence', {
            assessmentId: this.$route.params.assessmentId,
            competenceId: this.currentCompetence.id,
            comment: this.comment,
            commonAbility: this.commonValue,
            actionRequired: this.actionRequired,
          })
      this.showAssessmentComment = false
    },
    backClicked() {
      let stepId = --this.currentStep
      this.$router.push({
        name: 'assessment-process',
        params: {stepId},
      });
    },
    continueClicked() {
      if (this.currentStep < this.assessment.competences.length - 1) {
        this.$router.push({
          name: 'assessment-process',
          params: {stepId: ++this.currentStep, assessmentId: this.$route.params.assessmentId},
        });
      } else {
        this.$router.push({
          name: 'assessment-home',
          params: {assessmentId: this.$route.params.assessmentId, userId: this.$route.params.userId},
        });
      }
    }
    ,
    toSummaryClicked() {
      this.$router.push({
        name: 'assessment-home',
        params: {
          assessmentId: this.$route.params.assessmentId,
          userId: this.$route.params.userId,
          currentCompetence: this.currentCompetence.id
        },
      });
    }
    ,
    getCompetenceLevelDescription(value) {
      let compLevel = this.currentCompetence.competence_levels.find((competenceLevel) => {
        return competenceLevel.level === value
      });
      return compLevel.description;
    }
  },
  computed: {
    completedPercent() {
      if (this.assessment.competences.length > 0) {
        return Math.round(this.assessment.competences.filter((competence) => competence['common_ability'] > 0).length / this.assessment.competences.length * 100);
      }
      return null;
    },
  },
  watch: {
    commonValue: function () {
      this.commonFillValue = this.commonValue
    },
  }
};
</script>
