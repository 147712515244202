<template>
  <PageLayout v-if="assessment">
    <template #back-navigation><BackLink to="assessment-list" :label="$t('plannedAssessments')"></BackLink></template>
    <template #content-header>{{ assessment.title }}</template>
    <template #content-header-suffix>{{$helper.formatDate(assessment.dueDate)}}</template>
    <template #content>
      <div class="container">
         <AssessmentHelp :assessment="assessment"></AssessmentHelp>
      </div>
    </template>
    <template #content-footer>
      <PrimaryButton @click="startAssessmentClicked">
        {{ (assessment.state === 'open_assessment')?$t('startAssessment') : $t('startReview') }}
      </PrimaryButton>
    </template>
  </PageLayout>

</template>

<script>

import PageLayout from "../backend/PageLayout.vue";
import BackLink from "../../components/BackLink.vue";
import AssessmentHelp from "@/components/AssessmentHelp.vue";

export default {
  name: 'assessment-intro',
  components: {AssessmentHelp, BackLink, PageLayout},
  data() {
    return {
      assessment: null,
      assessee: null,
    };
  },
   created() {
    this.loading = true;
    this.getAssessment();

  },
  methods: {
    startAssessmentClicked() {
      this.$router.push({
        name: 'assessment-home',
        params: {assessmentId: this.$route.params.assessmentId, assesseeId: this.$route.params.assesseeId},
      });
    },
    getAssessment(){
      this.$store.dispatch('getAssessment', {id: this.$route.params.assessmentId}).then((res) => {
        this.assessment = res.data
        this.loading = false
      })
    }
  },
};
</script>
