<template>
  <div>
    <TreeTable
        :value="this.filteredNodes"
        class="node-tree"
        :expandedKeys="expandedKeys"
        @node-collapse="collapseNode"
        @node-expand="expandNode"
    >
      <template #header>
        <IconInput v-if="showSearch"
                   v-model="searchQuery"
                   :placeholder="$t('Search')"
                   type="text"
                   icon="pi pi-search"
                   :clear="true"
                   @clearClicked="collapseAll"
        ></IconInput>
      </template>
      <Column :field="columnField" :expander="showSubNodes" header="Title">
        <template #body="slotProps">
          <template v-if="slotProps.node">
            <template v-if="customNodeKey && slotProps.node.children.length<=0">
              <button @click="toggleNodes(slotProps.node)"
                    v-if="slotProps.node[customNodeKey].length>0"
                    class="p-treetable-toggler p-link custom-toggler"
                    style="margin-left: -38px; visibility: visible;">
                <i class="p-treetable-toggler-icon pi pi-fw"
                   v-bind:class="[expandedKeys[slotProps.node.id]?'pi-chevron-down':'pi-chevron-right']"></i>
              </button>
            </template>
            <span
                v-if="slotProps.node[columnField] && slotProps.node.color"
                class="custom-node-indicator"
                :style="'background:#'+slotProps.node.color"
            ></span>
            <div
                @click="$emit('nodeSelected', slotProps.node)"
                v-if="slotProps.node[columnField]"
                class="node-title p-2 m-0 font-weight-bold"
                v-bind:class="{'mb-0':subTitleField, 'text-muted':slotProps.node.deleted_at, 'node-highlight':slotProps.node.highlight, 'node-active': $route.name == routeName && slotProps.node.id == $route.params.id, 'node-selectable':nodeSelectable}"
            >{{ slotProps.node[columnField] }}
            </div>
            <div
                v-if="subTitleField"
                class="node-subtitle v-center mt-0"
                v-bind:class="{'text-muted':slotProps.node.deleted_at}"
            >{{ slotProps.node[subTitleField] }}
            </div>
            <div v-if="slotProps.node[customNodeKey] && expandedKeys[slotProps.node.id]"
                 class="custom-node-list">
              <div
                  v-for="(customNode) in slotProps.node[customNodeKey]"
                  :key="customNode.id"
                  @click="$emit('customNodeSelected', customNode)"
              >
                <div class="custom-node-title p-2" v-if="!customNode.hide"
                     v-bind:class="{'node-highlight':customNode.highlight, 'text-muted':customNode.deleted_at, 'node-active': $route.name == subRouteName && customNode.id == $route.params.id, 'node-selectable':subNodeSelectable }">
                  <div class="d-flex align-items-center"><span class="d-flex">{{
                      customNode[customColumnField]
                    }}</span>
                    <template v-if="customNode['email_verified_at']===null && customNode['deleted_at'] === null">
                      <span class="grow"></span>
                      <template v-if="customNode['email_sent_at']===null">
                        <span class="pi d-flex pi-envelope text-danger ml-1"></span>
                      </template>
                      <template v-else>
                        <span class="pi d-flex pi-send text-info ml-1"></span>
                      </template>
                    </template>
                    <template v-if="customNode['publish']===0">
                      <span class="grow"></span>
                      <span class="pi d-flex pi-eye-slash text-info ml-1"></span>
                    </template>
                  </div>
                  <span class="d-block custom-node-subtitle">{{ customNode[customSubTitleField] }}</span>
                </div>


              </div>
            </div>
          </template>


        </template>
      </Column>
    </TreeTable>
  </div>
</template>
<style lang="scss">
body .node-tree {
  .p-treetable-toggler {
    margin: 0;
  }

  button.p-treetable-toggler.p-link.custom-toggler:hover{
    color: $primary;
    background: none;
  }

  button.p-treetable-toggler.p-link.custom-toggler:focus {
    box-shadow:0 0 0 0.2rem $primary;
  }

    .custom-node-indicator {
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
    }

    .custom-node-expander {
      margin-top: 0.5em;
      cursor: pointer;
    }

    .p-treetable-header {
      background: transparent;
      border: none;
      position: sticky;
      top: 0;
      z-index: 5;

      .input {
        background: $dark;
      }
    }

    .node-title {
      display: inline-block;
      width: 80%;
    }

    .node-title.node-selectable {
      width: unset;
      cursor: pointer;
      margin-top: 0.6em;
      margin-bottom: 0.6em;
    }

    .node-subtitle.node-selectable {
      width: unset;
      cursor: pointer;
      margin-bottom: 0.6em;
      font-weight: 300;
    }

    .custom-node-subtitle {
      font-weight: 300;
    }

    .node-title.node-selectable:hover {
      border-radius: 25px;
      background: linear-gradient(to left, transparent, rgba($primary, 0.5));
    }

    .custom-node-title.node-selectable {
      cursor: pointer;
      margin-left: 38px;
      padding-bottom: 1px;
    }

    .custom-node-title.node-selectable:hover {
      border-radius: 25px;
      background: linear-gradient(to left, transparent, rgba($primary, 0.5));
    }

    .node-highlight {
      color: $primary;
    }

    .node-active {
      border-radius: 25px;
      background: linear-gradient(to left, transparent, rgba($primary, 0.5));
      font-weight: bolder;
    }
  }
</style>

<script>
import IconInput from "@/components/IconInput";

export default {
  name: 'TreeList',
  data() {
    return {
      searchQuery: null,
      expandedKeys: {},
      allNodes: null
    }
  },
  props: {
    customNodeKey: {
      type: String,
      default: null,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    nodes: {
      type: Array,
      default: null,
    },
    columnField: {
      type: String,
      default: "title"
    },
    subTitleField: {
      type: String,
      default: null
    },
    customColumnField: {
      type: String,
      default: "title"
    },
    customSubTitleField: {
      type: String,
      default: null
    },
    showSubNodes: {
      type: Boolean,
      default: true,
    },
    routeName: {
      type: String,
      default: null
    },
    subRouteName: {
      type: String,
      default: null
    },
    nodeSelectable: {
      type: Boolean,
      default: true
    },
    subNodeSelectable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    IconInput
  },
  created() {
    this.allNodes = this.nodes;
  },
  computed: {
    filteredNodes() {
      if (this.searchQuery) {
        this.expandAll()
        return this.nodes.filter(function search(item) {
              item.highlight = false;
              let itemFound = this.searchQuery.toLowerCase().split(' ')
                  .every(v => (item[this.columnField].toLowerCase().includes(v) || this.searchTag(item)))

              if (this.subTitleField) {
                itemFound ||= this.searchQuery.toLowerCase().split(' ')
                    .every(v => (item[this.subTitleField].toLowerCase().includes(v)))
              }

              if (item[this.customNodeKey]) {
                if (!itemFound) {
                  item[this.customNodeKey].map((customNode) => {
                    if (this.searchQuery.toLowerCase().split(' ')
                        .every(v => (!customNode[this.customColumnField].toLowerCase().includes(v)))) {
                      if (customNode[this.customSubTitleField]) {
                        if (this.searchQuery.toLowerCase().split(' ')
                            .every(v => (!customNode[this.customSubTitleField].toLowerCase().includes(v)))) {
                          customNode.hide = true;
                          customNode.highlight = false;
                          return customNode;
                        } else {
                          customNode.hide = false;
                          customNode.highlight = true;
                          return customNode;
                        }
                      } else {
                        customNode.hide = true;
                        customNode.highlight = false;

                      }
                      return customNode;
                    } else {

                      customNode.hide = false;
                      customNode.highlight = true;
                      this.expandNode(item)
                      return customNode;
                    }
                  })
                } else {
                  this.removeProp(item, 'hide')
                  item.highlight = true;
                  this.expandNode(item)
                }


                itemFound = item[this.customNodeKey].filter((node) => {
                  if (node[this.customSubTitleField]) {
                    return (this.searchQuery.toLowerCase().split(' ')
                            .every(v => (node[this.customSubTitleField].toLowerCase().includes(v))) ||
                        this.searchQuery.toLowerCase().split(' ')
                            .every(v => (node[this.customColumnField].toLowerCase().includes(v))))
                  } else {
                    return this.searchQuery.toLowerCase().split(' ')
                        .every(v => (node[this.customColumnField].toLowerCase().includes(v)))
                  }


                }).length > 0 || itemFound

              }
              if (this.customNodeKey) {
                if (item.children) {
                  return item.children.filter(search.bind(this)).length > 0 || itemFound
                } else {
                  return item[this.customNodeKey].length > 0 || itemFound;
                }
              } else {
                if (item.children) {
                  return item.children.filter(search.bind(this)).length > 0 || itemFound
                } else {
                  return itemFound
                }
              }
            }.bind(this)
        )
      } else {
        if (this.searchQuery === '') {
          this.collapseAll()
        } else {
          this.expandSavedNodes()
        }

        this.removeProp(this.nodes, 'hide')
        this.removeProp(this.nodes, 'highlight')
        return this.nodes
      }

    }
  },
  methods: {
    expandSavedNodes() {
      if (localStorage.getItem('expandedKeys')) {
        this.expandedKeys = JSON.parse(localStorage.getItem('expandedKeys'));
      } else {
        this.expandedKeys = {}
      }
    },
    removeProp(obj, propToDelete) {
      for (var property in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
          if (typeof obj[property] == "object") {
            this.removeProp(obj[property], propToDelete);
          } else {
            if (property === propToDelete && obj[property] === true) {
              delete obj[property];
            }
          }
        }
      }
    },
    toggleNodes(node) {
      if (this.expandedKeys[node.id]) {
        node.showCustomNodes = false;
        this.collapseNode(node);
      } else {
        node.showCustomNodes = true;
        this.expandNode(node)
      }
    },
    expandNodes(node) {
      node.showCustomNodes = true;
      this.expandedKeys = {...this.expandedKeys};
    },
    expandAll() {
      for (let node of this.nodes) {
        this.expandAllNodes(node);
        this.expandNodes(node);
      }
      this.expandedKeys = {...this.expandedKeys};
    },
    collapseAll() {
      this.expandedKeys = {};
      this.nodes.map((node) => {
        node.showCustomNodes = false;
      })
      this.expanded = false;
      localStorage.setItem('expandedKeys', [])
    },
    expandAllNodes(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.id] = true;
        for (let child of node.children) {
          this.expandNode(child);
          this.expandAllNodes(child)
        }
      }
      this.expandedKeys = {...this.expandedKeys}
    },
    expandNode(node) {
      node.showCustomNodes = true;
      this.expandedKeys[node.id] = true;
      this.expandedKeys = {...this.expandedKeys}
      localStorage.setItem('expandedKeys', JSON.stringify(this.expandedKeys))
    },
    collapseNode(node) {
      node.showCustomNodes = false;
      this.expandedKeys[node.id] = false;
      this.expandedKeys = {...this.expandedKeys}
      localStorage.setItem('expandedKeys', JSON.stringify(this.expandedKeys))
    },
    searchTag(item) {
      if (item.tags) {
        for (let tag of item.tags) {
          if (this.searchQuery.toLowerCase().split(" ").every(v => tag.name.toLowerCase().includes(v))) {
            return true
          }
        }
        return false
      }
    }
  },

}

</script>