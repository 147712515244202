import store from '../store'
import Vue from 'vue'
import i18n from '../i18n';
import moment from 'moment'

export default {
    hasAnyRole(roles) {
        if(!store.getters.userRoles){
            return false;
        }
        return store.getters.userRoles.some((role) => {
            return roles.includes(role)
        })
    },
    showErrorToast(error, title = null) {
        let message = null
        if (error.response) {
            error = error.response;
        }
        if (error.status === 422) {
            let validationMessages = null
            for (let validationMessage of Object.values(error.data.message)) {
                validationMessages += '<li>' + i18n.t(validationMessage) + '</li>';
            }
            if(validationMessages){
                message = '<ul>' + validationMessages + '</ul>'
            }
        } else {
            if (error.data) {
                if (error.data.message) {
                    message = i18n.t(error.data.message);
                }
            }
        }
        Vue.prototype.$toast.add({
            severity: 'error',
            summary: title ? i18n.t(title) : i18n.t('error.title'),
            detail: message,
            life: 3000,
        });
    },
    getAvailableLanguages() {
        return [
            {label: 'Deutsch', code: 'de'},
            {label: 'English', code: 'en'},
        ];
    },
    formatDate(value, format = 'DD.MM.YYYY') {
        return moment(value).format(format)
    }
}