<template>
  <PageLayout v-if="assessment">
    <template #back-navigation>
      <BackLink to="assessment-home" :params="{assessmentId:$route.params.assessmentId}"
                :label="$t('toSummary')"></BackLink>
    </template>
    <template #content-header>
      {{ assessment.title }}
    </template>
    <template #content-header-suffix>
      {{ assessment.dueDate }}
    </template>
    <template #content>
      <div class="container">


        <div class="my-4">
          {{
            $t('GREAT! You have now, based on self-image and external image, developed a coordinated picture of the current ABILITY. ' +
                'This summary gives you an overview of all your comments on the conversation and, if applicable, already determined desires for LEARNING.')
          }}
        </div>
        <div v-if="reviewCompetences">
          <Accordion>
            <template v-for="(reviewCompetence, key) of reviewCompetences">
              <AccordionTab :key="key">
                <template #header>
                  <div class="d-flex justify-content-between w-100">
                    <div class="d-inline">
                      {{ reviewCompetence.competence_category.title }}
                      <span class="px-1 font-weight-lighter"
                            :style="{'border-left': '2px solid #'+reviewCompetence.competence_category.color}">
                    {{ reviewCompetence.title }}
                     </span>

                    </div>
                    <div>
                      <i class="pi pi-comment competence-comment available mx-2" v-if="reviewCompetence.pivot.comment"></i>
                      <i v-else class="pi pi-comment competence-comment mx-2"></i>
                      <i
                          v-if="reviewCompetence.pivot.action_required"
                          class="pi pi-flag-fill float-right competence-flag flagged mx-2"></i>
                      <i
                          v-else
                          class="pi pi-flag float-right competence-flag mx-2"></i>
                    </div>
                  </div>


                </template>
                <div class="my-2 font-weight-light">
                  {{ reviewCompetence.description }}
                </div>
                <div v-if="reviewCompetence.pivot.comment">
                  <div class="d-flex align-center mt-4">
                    <i class="pi pi-comment competence-comment available  mx-2"></i>
                    <div class="overflow-auto">{{ reviewCompetence.pivot.comment }}</div>
                  </div>
                </div>
              </AccordionTab>
            </template>
          </Accordion>
        </div>

      </div>
    </template>

    <template #content-footer>
      <div class="flex flex-column align-items-center justify-content-center">
        <div :class="{ 'form-group--error': $v.summary.$error }">
          <div class="form-group">
            <label for="title">{{ $t('Summary') }}</label>
            <Textarea id="summary" type="text" v-model="summary" :autoResize="true" :cols="50"/>

          </div>

          <div class="error d-flex text-danger" v-if="!$v.summary.maxLength">
            {{ $t('Only {maxLength} Characters allowed', {maxLength: 4000}) }}
          </div>
          <PrimaryButton class="d-flex align-items-center justify-content-center" @click="finishClicked">
            {{ $t('complete') }}
          </PrimaryButton>
        </div>


      </div>


    </template>
  </PageLayout>

</template>

<script>
import PageLayout from "../backend/PageLayout";
import {maxLength} from 'vuelidate/lib/validators';
import BackLink from "../../components/BackLink.vue";

export default {
  name: 'assessment-review',
  data() {
    return {
      reviewCompetences: null,
      summary: null,
      assessment: null
    };
  },
  created() {
    this.getReviewCompetences()
    this.getAssessment()
  },
  methods: {
    getReviewCompetences() {
      this.$store.commit('setLoading', 'loading');
      this.$http.get('/review-assessment/' + this.$route.params.assessmentId)
          .then((res) => {
            this.reviewCompetences = res.data;
            this.$store.commit('setLoading', 'success');
          })
          .catch(err => {
            this.$store.commit('setLoading', 'success');
            if (err.response) {
              this.$toast.add({
                severity: 'error',
                summary: this.$t('Could not load assessment review '),
                detail: err.response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: 'error',
                summary: this.$t('Could not load assessment review ') + err,
                detail: err,
                life: 3000,
              });
            }
            this.$router.push('/');
          })
    },
    finishClicked() {
      this.$v.summary.$touch();
      if (this.$v.summary.$invalid) {
        this.submitStatus = 'ERROR';
        this.$toast.add({
          severity: 'error',
          summary: this.$t('The form has errors'),
          detail: this.$t('Please check the form again'),
          life: 3000,
        });
      } else {
        this.$store.commit('setLoading', 'loading');
        this.$http.post('/finish-assessment/' + this.$route.params.assessmentId, {summary: this.summary})
            .then(() => {
              this.$store.commit('setLoading', 'success');
              this.$router.push({
                name: 'assessment-summary',
                params: {assessmentId: this.$route.params.assessmentId, assesseeId: this.$route.params.assesseeId},
              });
              this.$toast.add({
                severity: 'success',
                summary: 'Assessment erfolgreich abgeschlossen',
                life: 3000,
              });
            })
            .catch(err => {
              this.$store.commit('setLoading', 'success');
              this.$toast.add({
                severity: 'error',
                summary: 'Asessment nicht abgeschlossen',
                detail: err,
                life: 3000,
              });
            });
      }
    },
    getAssessment() {
      this.$store.dispatch('getAssessment', {id: this.$route.params.assessmentId}).then((res) => {
        this.assessment = res.data
        this.loading = false
      })
    }
  },
  components: {
    BackLink,
    PageLayout,
  },
  validations: {
    summary: {
      maxLength: maxLength(4000)
    },
  },
};
</script>

<style lang="scss">
body .p-accordion .p-accordion-header a:hover {
  text-decoration: none !important;
}
</style>
