<template>
  <PageLayout id="self-assessment-process">
    <template #back-navigation>
       <BackLink to="self-assessment-home" :params="{assessmentId:assessment.id}" :label="$t('toSummary')"/>
    </template>
    <template #content-header>
      {{ $t('selfImage') }}
    </template>
    <template #content-header-suffix>
      <div>
        <div class="px-2" :style="{'border-left': '2px solid #'+currentCompetence.competence_category.color}">
          <div>
            {{ currentCompetence.title }}
          </div>
          <div class="d-flex justify-content-center">
          <span class="font-weight-lighter" style="font-size:10pt;">Kompetenz {{ currentStep + 1 }} von {{
              assessment.competences.length
            }}</span>
          </div>
        </div>

      </div>
    </template>
    <template #content>
      <div class="container pt-0" v-if="currentCompetence">
        <div class="pt-0 competence-title-description mb-4">= {{ currentCompetence.description }}</div>

        <p class="question-title text-center mb-5 mt-9 pb-5">{{ $t('ability') }}</p>
        <div class="Slider container px-0 px-sm-5 sliders">
          <div class="prepend-line double"></div>
          <v-slider
              class="custom-slider ability-slider-fill"
              v-model="abilityFillValue"
              min="1"
              max="8"
              steps="6"
              color="#dc8e00"
              track-color="#C3C8CC"
              :readonly=true
          ></v-slider>
          <v-slider
              class="custom-slider ability-slider"
              :class="{ 'disabled': !abilityActive }"
              v-model="abilityValue"
              min="0"
              max="8"
              @start="abilityDragStart"
              @click="abilityDragEnd"
              @end="abilityDragEnd"
              steps="7"
              :ticks="(user.show_ticks)?'always':false"
              color="transparent"
              track-color="transparent"
              thumb-size="60"
              thumb-label="always"
              thumb-color="transparent"
          >
            <template v-slot:thumb-label>
              <img v-if="abilityActive" class="selfassessment-tooltip-icon" src="@/assets/icons/k.svg"/>
              <img v-if="!abilityActive" class="selfassessment-tooltip-icon" src="@/assets/icons/k-gray.svg"/>
            </template>
            <template v-slot:prepend>
              <div class="prepend-indicator">
                <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="50 0, 65 200, 35 200"/>
                </svg>
                <span class="label">{{ $t('novice') }}</span>
              </div>
            </template>
            <template v-slot:append>
              <div class="append-indicator">
                <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="50 0, 65 200, 35 200"/>
                </svg>
                <span class="label">{{ $t('expert') }}</span>
              </div>
            </template>
          </v-slider>

          <v-slider
              class="custom-slider claim-slider-fill"
              v-model="claimFillValue"
              min="1"
              max="8"
              steps="6"
              color="#dc8e00"
              track-color="#C3C8CC"
              :readonly=true
          ></v-slider>
          <v-slider
              class="custom-slider claim-slider"
              :class="{ 'disabled': !claimActive }"
              v-model="claimValue"
              min="0"
              max="8"
              @start="claimDragStart"
              @click="claimDragEnd"
              @end="claimDragEnd"
              steps="7"
              :ticks="(user.show_ticks)?'always':false"
              color="transparent"
              track-color="transparent"
              thumb-size="60"
              thumb-label="always"
          >
            <template v-slot:thumb-label>
              <img v-if="claimActive" class="selfassessment-tooltip-icon" src="@/assets/icons/w.svg"/>
              <img v-if="!claimActive" class="selfassessment-tooltip-icon" src="@/assets/icons/w-gray.svg"/>
            </template>
          </v-slider>
        </div>
        <div class="container mt-5" v-if="currentCompetence.competence_levels">
          <div v-if="active == 'ability'">
      <span v-if="abilityValue <= 0">
        <div class="competence-title-container">
          <span class="competence-title text-uppercase">{{ $t('ability') }}</span>
           <span
               class='competence-title-suffix'> {{ $t('Rating') }}</span>
        </div>
      </span>
            <div class='competence-title-container' v-if="abilityValue>0">
              <span class='competence-title'>{{
                  $t('competences.levelTitles.level' + (abilityValue) + '.title')
                }}</span>
              <span
                  class='competence-title-suffix'> {{
                  $t('competences.levelTitles.level' + (abilityValue) + '.suffix')
                }}</span>
            </div>
          </div>
          <div v-if="active == 'claim'">
        <span v-if="claimValue <= 0">
          <div class="competence-title-container">
            <span class="competence-title">{{ $t('desire') }}</span>
             <span
                 class='competence-title-suffix'> {{ $t('Rating') }}</span>
          </div>
        </span>
            <div class='competence-title-container' v-if="claimValue>0">
              <span class='competence-title'>{{ $t('competences.levelTitles.level' + (claimValue) + '.title') }}</span>
              <span
                  class='competence-title-suffix'> {{
                  $t('competences.levelTitles.level' + (claimValue) + '.suffix')
                }}</span>
            </div>
          </div>
        </div>
        <div
            class="container competence-level-description"
            v-if="currentCompetence.competence_levels"
        >
      <span
          v-if="abilityValue > 0 && active == 'ability' && currentCompetence.competence_levels.length > 1"
          v-html="getCompetenceLevelDescription(abilityValue)"
      ></span>
          <span v-if="abilityValue <= 0 && active == 'ability'">{{
              currentCompetence.competence_levels[0].description
            }}</span>
          <span
              v-if="claimValue > 0 && active == 'claim'" v-html="getCompetenceLevelDescription(claimValue)"
          ></span>
          <span
              v-if="claimValue <= 0 && active == 'claim'"
          >{{ currentCompetence.competence_levels[1].description }}</span>
        </div>
      </div>
    </template>
    <template #content-footer>
        <div>
          <SecondaryButton v-if="currentStep>0"
                           @click="backClicked"
          >{{ $t('back') }}
          </SecondaryButton>
          <RouterLink v-else :to="{name: 'self-assessment-home', params:{assessmentId:assessment.id}}">
            <SecondaryButton
            >{{ $t('toSummary') }}
            </SecondaryButton>
          </RouterLink>
        </div>
        <div class="flex-grow-1 text-center competence-progress mx-5 my-2">
          <ProgressBar :value="completedPercent">{{ completedPercent }}%</ProgressBar>
        </div>
        <div>
          <PrimaryButton
              v-if="currentStep < assessment.competences.length - 1"
              @click="continueClicked"
          >{{ $t('continue') }}
          </PrimaryButton>
          <button
              v-if="currentStep >= assessment.competences.length - 1"
              class="btn btn-primary btn-block text-uppercase rounded-pill center-button"
              @click="continueClicked"
          >{{ $t('complete') }}
          </button>
        </div>
    </template>
  </PageLayout>
</template>
<style lang="scss">
</style>
<script>
import PageLayout from "../backend/PageLayout.vue";
import BackLink from "../../components/BackLink.vue";

export default {
  name: 'selfassessment-process',
  components: {BackLink, PageLayout},
  data() {
    return {
      user: this.$store.getters.user,
      assessee: null,
      currentCompetence: {},
      competenceId: null,
      currentStep: Number(this.$route.params.stepId),
      data: [],
      abilityValue: 0,
      abilityFillValue: 1,
      assessment: null,
      claimActive: false,
      abilityActive: true,
      claimValue: 0,
      claimFillValue: 1,
      active: 'ability',
    };
  },
  created: function () {
    this.$store.commit('setLoading', 'loading');
    this.getAssessment()
    this.$http.get('users/' + this.assessment.assessee_id)
        .then((res) => {
          this.assessee = res.data
        })
    this.getCompetence();
  },
  methods: {
    getAssessment() {
      this.$http.get('/assessmentCompetences/' + this.$route.params.assessmentId)
          .then((res) => {
            this.assessment = res.data;
            this.assessee = res.data.assessee;
            if (this.currentStep < this.assessment.competences.length) {
              this.getCompetence();
            }
          })
    },
    continueClicked() {
      if (this.currentStep < this.assessment.competences.length - 1) {
        console.log('pushing to ', this.assessment.id);
        this.$router.push({
          name: 'selfassessment-process',
          params: {assessmentId: this.assessment.id, stepId: ++this.currentStep},
        });
      } else {
        this.$router.push({name: 'assessment-list'});
      }
    },
    backClicked() {
      let stepId = --this.currentStep
      this.$router.push({
        name: 'selfassessment-process',
        params: {stepId},
      });
    },
    endClicked() {
      this.$router.push({name: 'home'});
    },
    getCompetence() {
      this.$store.commit('setLoading', 'loading');
      this.competenceId = this.assessment.competences[
          this.currentStep
          ].id;

      this.$http.get('/competences/' + this.competenceId).then(res => {
        this.currentCompetence = res.data;

        this.data = this.currentCompetence.competence_levels.map(res => {
          return res.level - 1;
        });
        this.data.push(this.data.length);
        this.currentCompetence.competence_levels.unshift(
            {
              title: 'Können',
              description: this.$t('selfassessment.setAbilityInfo'),
            },
            {
              title: 'Wollen',
              description: this.$t('selfassessment.setDesireInfo'),
            },
        );
        console.log("Current step ", this.currentStep)
        let competenceIndex = this.user.competences.findIndex((competence) => {
          return competence.id === this.currentCompetence.id
        })
        this.abilityValue = this.user.competences[competenceIndex].self_ability;
        this.claimValue = this.user.competences[competenceIndex].self_claim;
        this.$store.commit('setLoading', 'success');
      });
    },
    abilityDragStart() {
      this.claimActive = false;
      this.abilityActive = true;
      this.active = 'ability';
    },
    abilityDragEnd() {
      this.user.competences[this.currentStep].self_ability = this.abilityValue;
      this.$store
          .dispatch('postUserCompetence',
              {id: this.user.id, competenceId: this.competenceId, selfAbility: this.abilityValue},
          )
          .then(() => {
            console.log('successfully posted ability', this.abilityValue);
            this.assessment.competences[this.currentStep].self_ability = this.abilityValue
            this.$store.commit('setLoading', 'success');
          })
          .catch(err => {
            console.log('error posting claim ', err);
          });
    },
    claimDragStart() {
      this.claimActive = true;
      this.abilityActive = false;
      this.active = 'claim';
      console.log('claim value ', this.abilityActive);
    },
    claimDragEnd() {
      this.user.competences[this.currentStep].claim_ability = this.claimValue;
      console.log("claimValue", this.claimValue)
      this.$store
          .dispatch('postUserCompetence',
              {id: this.user.id, competenceId: this.competenceId, selfClaim: this.claimValue}
          )
          .then(() => {
            console.log('successfully posted claim');
          })
          .catch(err => {
            console.log('error posting claim ', err);
          });
    },
    getCompetenceLevelDescription(value) {
      let compLevel = this.currentCompetence.competence_levels.find((competenceLevel) => {
        return competenceLevel.level == value
      });
      if (compLevel) {
        return compLevel.description;
      }
    }
  },
  computed: {
    completedPercent() {
      if (this.assessment.competences.length > 0) {
        return Math.round(this.assessment.competences.filter((competence) => competence['self_ability'] > 0).length / this.assessment.competences.length * 100);
      }
      return null;
    },
  },
  watch: {
    abilityValue: function () {
      this.abilityFillValue = this.abilityValue
    },
    claimValue: function () {
      this.claimFillValue = this.claimValue
    },
  }
};
</script>
