<template>
  <PageLayout>
    <template #back-navigation>
      <BackLink to="assessment-list" :label="$t('plannedAssessments')"></BackLink>
    </template>
    <template #content-header>
      <span v-if="assessment.state === 'review_assessment'">{{ $t('Review') }} | </span>{{ assessment.title }}
      <Dialog :visible.sync="showAssessmentHelp" :modal="true" :maximizable="true" :dismissableMask="true">
        <template #header>
          <div class="mx-auto">
            <span class="font-weight-bolder">{{ $t('Assessment') }} </span>
            <span class="font-weight-lighter">{{ $t('Help') }}</span>
          </div>
        </template>
        <AssessmentHelp :assessment="assessment"></AssessmentHelp>
      </Dialog>
    </template>
    <template #content-header-suffix>
      {{ $moment(assessment.dueDate).format('D.M.Y') }}
    </template>
    <template #content>
      <div class="container" v-if="assessmentCompetences">
        <div class="d-flex justify-content-end">
          <i @click="()=>showAssessmentHelp=true" class="text-white pi pi-question-circle p-2 cursor-pointer"></i>
        </div>
        <CompetenceGrid
            :title="$t('Competences')"
            :subtitle="assessee.first_name"
            :competences="assessmentCompetences"
            @competenceSelected="competenceClicked"
            @commentSelected="commentSelected"
            @competenceFlagged="competenceFlagged"
            :show-claim="true"
            :show-public-ability="assessment.state !== 'review_assessment'"
            :show-common-ability="true"
            :show-ability="assessment.state !== 'review_assessment'"
            :show-current-job="showCurrentJob"
            :show-future-job="futureJobData"
            :show-job-abilities="true"
            :showActionRequired="true"
            :stateKey="'assessment-summary-'+assessment.id"
            :showComment="true"
        >
          <template #options>
            <template v-if="assessment.state === 'review_assessment'">
              <div class="job-switcher align-items-center d-flex w-50"
                   :class="{ 'w-100 justify-end': assessment.state === 'open_assessment', 'justify-center': assessment.state === 'review_assessment' }">
                <div class="v-center current">
                  <InputSwitch v-model="showCurrentJob"/>
                  <div class="ml-2 text-left">
                <span class="font-weight-light">
                  {{ assessee.job_position.title }}
                </span>
                  </div>
                </div>
              </div>

              <div class="job-switcher w-50 mx-2">
                <div class="v-center future">
                  <InputSwitch v-model="futureJobData"/>
                  <div class="ml-5 text-left flex-grow-1">
                    <div class="pt-0 mt-0 flex-grow-1">
                      <PickerInput id="assessment-home-future-job-picker"
                                   :title="$t('Select JobPosition')"
                                   :selected="selectedFutureJobPosition.title"
                                   :placeholder="$t('Select future job position')"
                                   v-model="selectedFutureJobPosition.id"
                                   :show-clear="false"
                                   ref="future-job-position-picker">
                        <TreeList :nodes="jobPositions"
                                  @nodeSelected="jobPositionChanged"
                        ></TreeList>
                      </PickerInput>
                    </div>
                  </div>
                </div>
              </div>
            </template>

          </template>

        </CompetenceGrid>
      </div>
      <Dialog :visible.sync="showAssessmentComment" :modal="true" :maximizable="true" :dismissableMask="true">
        <template #header>
          <div class="mx-auto">
            <span class="font-weight-bolder">{{ $t('Comment') }} </span>
            <span class="font-weight-lighter">{{ commentCompetence.title }}</span>
          </div>
        </template>
        <div v-if="commentCompetence">
          <Textarea v-model="commentCompetence.comment" :autoResize="true"></Textarea>
        </div>

        <template #footer>
          <FormActions>
            <template #left>
              <SecondaryButton @click="()=>showAssessmentComment=false"> {{ $t('Cancel') }}</SecondaryButton>
            </template>
            <PrimaryButton @click="updateComment"> {{ $t('save') }}</PrimaryButton>
          </FormActions>
        </template>
      </Dialog>
    </template>

    <template #content-footer>
      <div class="container">
        <p v-if="assessment.state === 'review_assessment'" class="text-center mb-0">{{
            $t('All assessment competences have been successfully evaluated. In the next step, a summary of the assessment will be displayed.')
          }}</p>

        <div class="d-flex justify-content-center">
          <div class="col-3" v-if="assessment.state !== 'review_assessment'">
            <button
                class="btn btn-primary btn-block text-uppercase rounded-pill finish-btn"
                @click="reviewClicked" v-if="completedPercent>=100"
            >{{ $t('continue') }}
            </button>
          </div>
          <div class="col-3" v-if="assessment.state === 'review_assessment'">
            <button
                class="btn btn-primary btn-block text-uppercase rounded-pill finish-btn"
                @click="completeClicked" v-if="completedPercent>=100"
            >{{ $t('continue') }}
            </button>
          </div>
        </div>
        <div class="row">
          <div class="offset-1 col-2 d-flex justify-content-center v-center">
            <span>
              <img class src="@/assets/icons/circle.svg" alt="circle"/>
            </span>
            <small class="mb-0 ml-2 text-uppercase">{{ $t('abilityCoordinated') }}</small>
          </div>
          <div class="col-2 d-flex justify-content-center v-center" v-if="assessment.state !== 'review_assessment'">
            <span>
              <img class src="@/assets/icons/triangle.svg" alt="triangle"/>
            </span>
            <small class="mb-0 ml-2 text-uppercase">{{ $t('abilityPublic') }}</small>
          </div>
          <div class="col-2 d-flex justify-content-center v-center" v-if="assessment.state !== 'review_assessment'">
            <span>
              <img class src="@/assets/icons/triangle.svg" alt="polygon" style="transform: rotate(180deg);"/>
            </span>
            <small class="mb-0 ml-2 text-uppercase">{{ $t('abilitySelf') }}</small>
          </div>
          <div class="col-2 d-flex justify-content-center v-center">
            <span>
              <img class src="@/assets/icons/circle-claim.svg" alt="circle-claim"/>
            </span>
            <small class="mb-0 ml-2 text-uppercase">{{ $t('desireSelf') }}</small>
          </div>
          <div class="col-2 d-flex justify-content-center v-center">
            <span>  <img class="" src="@/assets/icons/diamound-green.svg" alt="diamound-green"/></span><small
              class="mb-0 ml-2 text-uppercase">{{ $t('requirementCurrent') }}</small>
          </div>
          <div class="col-2 d-flex justify-content-center v-center" v-if="assessment.state === 'review_assessment'">
            <span>  <img class="" src="@/assets/icons/diamound-blue.svg" alt="diamound-blue"/></span><small
              class="mb-0 ml-2 text-uppercase">{{ $t('requirementFuture') }}</small>
          </div>
          <div class="col-2 d-flex justify-content-center v-center" v-if="assessment.state === 'review_assessment'">
            <span><i class="pi pi-flag-fill competence-flag flagged mx-2"></i></span><small
              class="mb-0 ml-2 text-uppercase">{{ $t('learn') }}</small>
          </div>
        </div>
      </div>
    </template>

  </PageLayout>

</template>

<script>
import CompetenceGrid from '@/components/CompetenceGrid.vue';
import PageLayout from "../backend/PageLayout";
import BackLink from "../../components/BackLink.vue";
import PickerInput from "@/components/PickerInput.vue";
import TreeList from "@/components/TreeList.vue";
import AssessmentHelp from "@/components/AssessmentHelp.vue";

export default {
  name: 'assessment-home',
  data() {
    return {
      assessment: null,
      assessee: null,
      competences: [],
      assessmentCompetences: null,
      selectedCompetence: null,
      expanded: true,
      expandedInner: true,
      showCurrentJob: false,
      currentJobTitle: null,
      futureJobData: false,
      futureJobTitle: null,
      expandedKeys: {},
      jobPositions: [],
      selectedFutureJobPosition: {
        id: null,
        title: null
      },
      showAssessmentComment: false,
      showAssessmentHelp: false,
      commentCompetence: null,
    };
  },
  created() {
    this.getAssessment()
    this.getCompetences();
  },
  methods: {
    onNodeSelect(node) {
      this.expandedKeys = {};
      this.expandNode(node);
    },
    getAssessment() {
      this.$http.get('/assessments/' + this.$route.params.assessmentId)
          .then((res) => {
            this.assessment = res.data
          })
    },
    getCompetences() {
      this.$store
          .dispatch('getEmployee', {id: this.$route.params.assesseeId})
          .then(res => {
            this.$store.commit('setLoading', 'loading');
            this.assessee = res.data;
            this.$http
                .get('job-positions', {})
                .then(res => {
                  this.jobPositions = res.data;
                  if (!this.selectedFutureJobPosition && this.assessee.future_job_position) {
                    this.selectedFutureJobPosition = this.jobPositions.find(position => {
                      return position.id === this.assessee.future_job_position.id;
                    });
                  }
                })
                .then(() => {
                  this.assessmentCompetences = null
                  let url = '/assessment-competences-tree/' + this.$route.params.assessmentId
                  if (this.selectedFutureJobPosition.id) {
                    url += '/' + this.selectedFutureJobPosition.id
                  }
                  this.$http
                      .get(url)
                      .then(res => {
                        this.currentJobTitle = this.assessee.job_position.title;
                        this.futureJobTitle = (this.assessee.future_job_position) ? this.assessee.future_job_position.title : null;
                        this.assessmentCompetences = null;
                        this.assessmentCompetences = res.data;
                        this.$store.commit('setLoading', 'success');
                      })
                      .catch(err => {
                        this.$toast.add({
                          severity: 'error',
                          summary: 'Kompetenzen nicht geladen',
                          detail: err,
                          life: 3000,
                        });
                      });
                })
          })
          .catch(err => {
            this.$toast.add({
              severity: 'error',
              summary: 'Mitarbeiter nicht geladen',
              detail: err,
              life: 3000,
            });
          });
    },
    competenceClicked(competence) {
      let stepId = this.assessmentCompetences.findIndex(assessmentCompetence => {
        return assessmentCompetence.id === competence.id;
      });
      if (stepId >= 0) {
        this.$router.push({
          name: 'assessment-process',
          params: {stepId, assessmentId: this.$route.params.assessmentId, assesseeId: this.$route.params.assesseeId},
        });
      }
    },
    competenceFlagged(competence) {
      let actionRequired = !competence.action_required
      this.$store
          .dispatch('postAssessmentCompetence', {
            assessmentId: this.$route.params.assessmentId,
            competenceId: competence.id,
            actionRequired: actionRequired
          },)
          .then(() => {
            let flaggedCompetenceIndex = this.assessmentCompetences.findIndex((assessmentCompetence) => {
              return assessmentCompetence.id === competence.id
            })
            this.assessmentCompetences[flaggedCompetenceIndex].action_required = actionRequired
            this.$toast.add({
              severity: 'success',
              summary: (actionRequired) ? this.$t('Flag successfully added') : this.$t('Flag successfully removed'),
              life: 3000,
            });
          })
    },
    commentSelected(competence) {
      this.showAssessmentComment = true
      this.commentCompetence = competence
    },
    updateComment() {
      this.$store
          .dispatch('postAssessmentCompetence', {
            assessmentId: this.$route.params.assessmentId,
            competenceId: this.commentCompetence.id,
            comment: this.commentCompetence.comment
          },)
          .then(() => {
            let commentCompetenceIndex = this.assessmentCompetences.findIndex((assessmentCompetence) => {
              return assessmentCompetence.id === this.commentCompetence.id
            })
            this.assessmentCompetences[commentCompetenceIndex].comment = this.commentCompetence.comment
            this.$toast.add({
              severity: 'success',
              summary: this.$t('Comment successfully updated'),
              life: 3000,
            });
            this.commentCompetence = null
            this.showAssessmentComment = false
          })
    }
    ,
    toSummaryClicked() {
      this.$router.push({
        name: 'assessment-list',
      });
    },

    reviewClicked() {
      this.$router.push({
        name: 'assessment-outro',
      });
    },
    completeClicked() {
      this.$router.push({
        name: 'assessment-finish',
        params: {assessmentId: this.assessment.id, assesseeId: this.assessment.assessee.id},
      });
    },
    jobPositionChanged(futureJobPosition) {
      this.selectedFutureJobPosition = futureJobPosition
      this.assessmentCompetences = null
      this.getCompetences();
      this.$refs["future-job-position-picker"].closeDialog()
    },
    toggle(event, competenceId) {
      console.log(event);
      this.$refs['op-' + competenceId][0].toggle(event);
    },
    convertHex: function (color, opacity) {
      let r = parseInt(color.substring(0, 2), 16);
      let g = parseInt(color.substring(2, 4), 16);
      let b = parseInt(color.substring(4, 6), 16);
      return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    },
  },
  computed: {
    completedPercent() {
      if (this.assessmentCompetences) {
        let competenceCount = Object.assign(this.assessmentCompetences);
        return Math.round(competenceCount.filter((competence) => competence['common_ability'] > 0).length / this.assessmentCompetences.length * 100);
      }
      return null;
    }
  },
  components: {
    AssessmentHelp,
    TreeList,
    PickerInput,
    BackLink,
    PageLayout,
    CompetenceGrid,
  },
};
</script>

<style lang="scss">
#assessment-home-future-job-picker .p-inputtext {
  padding: 1px 8px;
}

.legend {
  .common-progress {
    .p-progressbar {
      width: 80%;
      margin: 0 auto;
      height: 4px;

      .p-progressbar-value {
        background: $primary !important;
      }

    }
  }

  position: absolute;
  bottom: 0;
  width: 100%;

  .legend-icon {
    float: left;
  }

  h5 {
    color: $text-white;
  }
}

.assessment-col {
  height: 100%;
  width: 102%;
}

.page-content-assessment {
  max-height: 75vh;
  overflow: auto;
}

.competences-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;

  .row:first-child {
    margin-top: 4px;
  }

  .row:last-child {
    margin-bottom: 8px;
  }

  .row {
    border-top: 2px solid $dark;
    margin-left: 1em;
    padding-left: 0.5em;
  }
}

.assessment-tree {
  margin-top: 1.4em;
  overflow-x: hidden;

  .p-accordion {
    width: 102% !important;
    margin-top: -3px;
  }
}


.assessment-tree .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
  background-color: transparent !important;
  border-top: 2px solid $dark !important;
  border-bottom: 2px solid $dark !important;
  border-right: none !important;
  border-left: none !important;
}

.p-treetable-thead {
  display: none;
}

span.p-treetable-toggler.p-unselectable-text {
  //position: absolute !important;
  float: left;
  right: 67% !important;
  color: white !important;
  z-index: 2 !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  position: sticky;
}

body .p-treetable .p-treetable-tbody > tr:focus > td {
  box-shadow: none !important;
  outline: none !important;
}

.p-treetable-tbody {
  .pi {
    line-height: unset;
  }

  .p-accordion-toggle-icon {
    position: absolute;
    right: 69%;
  }

  td {
    position: relative;
    color: white;
    border-top: 2px solid $dark !important;
    border-bottom: 2px solid $dark !important;
    border-left: none !important;
    border-right: none !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;

    .competence-indicator {
      position: absolute;
      left: 0;
      // top: 0;
      width: 5px;
      height: 100%;
    }

    .competence-row-background {
      position: absolute;
      left: 5px;
      top: 0;
      width: 32.7%;
      height: 100%;
      z-index: 0;
    }

    .competence-header-title {
      width: 25%;
      word-break: break-all;
    }

    h5 {
      font-size: 1em !important;
      font-weight: bold;
      margin-right: 1em;
    }

    h5.competence-title {
      font-weight: normal;
      width: 21%;
      word-break: break-word;
      overflow: hidden;
      height: 100%;
    }

    .chart-content {
      padding: 0 !important;
      position: absolute;
      right: 0;
      width: 600px;
    }
  }

  tr {
    background: transparent !important;
  }
}

.category-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
}

.finish-btn {
  min-width: 100px !important;
  margin: 0 auto;
  margin-left: 1em;
}

.v-center {
  display: flex;
  align-items: center;
}

.header-col {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  width: 12.5%;
}

.sub-title.header-col,
.sub-title.header-col-bold {
  background: rgba(255, 255, 255, 0.1);
}

.header-col-bold {
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  width: 12.5%;
}

.assessment-home {
  .level-title {
    color: white;
    font-size: 1em !important;
  }

  .sub-title {
    color: $light-gray;
  }
}

.job-switcher .sub-title {
  overflow: hidden;
  border-bottom: 1px solid $light-gray;
  white-space: pre;
}

body .assessment-tree {
  padding-right: 0;

  .p-accordion-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: transparent !important;
  }
}

.job-switcher.description {
  color: $light-gray;
}

.user-img-list {
  display: inline;
  margin-right: 1em;

  img {
    width: 50px !important;
    height: 50px !important;
  }
}

.user-line {
  background: $dark;
}

.rate-button {
  min-width: 0;
}

.table-container {
  background: none !important;
  box-shadow: none !important;
}

.grid-title {
  height: 2.5em;
  width: 35%;
  left: 30px;
  top: -2em;
  z-index: 2;
  position: absolute;

  float: left;
}

.title {
  display: block;
  color: white;
  width: 100%;
  max-width: 400px;
  max-height: 29px;
  overflow: hidden;
}

.subtitle {
  display: block;
  width: 100%;
  line-height: 1em !important;
  font-size: small;
}

@media screen and (max-width: $break-x-large) {
  .p-treetable-tbody td .chart-content {
    width: 575px;
  }
}

@media screen and (max-width: $break-large) {
  .page-content-assessment {
    max-height: 70vh !important;
  }
  .p-treetable-tbody td .chart-content {
    width: 490px;
  }
}

@media screen and (max-width: $break-medium) {
  .level-title h5,
  .sub-title {
    font-size: 0.9em;
  }
  .assessment-tree,
  .assessment-col,
  .job-switcher {
    font-size: 0.9em;
  }
  .page-content-assessment {
    max-height: 35vh !important;
  }
  .assessment-tree,
  .assessment-col {
    font-size: 0.9em;
  }
  .finish-btn {
    margin-left: 0 !important;
  }
  .level-grid {
    padding-bottom: 5.5em;
  }

}

@media screen and (max-width: $break-small) {
  .page-content-assessment {
    max-height: 35vh !important;
  }
  .assessment-tree,
  .assessment-col {
    font-size: 0.9em;
  }
  .finish-btn {
    margin-left: 0 !important;
  }
}
</style>
