<script>
import tourStepsDE from "@/locales/tourSteps/tourStepsDE.json";
import tourStepsEN from "@/locales/tourSteps/tourStepsEN.json";
import VueTour from "@/components/VueTour.vue";

export default {
  name: 'VueTourWrapper',
  components: {VueTour},
  data() {
    return {
      groups: [],
      steps: (this.$store.state.user.language == 'en') ? tourStepsEN : tourStepsDE,
    }
  },
  created() {
    this.loadGroups()
  },
  watch: {
    '$route': function () {
      this.loadGroups()
    }
  },
  methods: {
    loadGroups() {
      if (this.$store.getters.isLoggedIn) {
        this.groups = Object.keys(this.steps)

        this.$store.dispatch('getUser', {}).then(() => {
          if (this.$store.state.user) {
            if (this.$store.state.user.tour_enabled) {
              if (this.$store.state.user.tour_finished) {
                if (this.$store.state.user.tour_finished.length > 0) {
                  this.groups = this.groups.filter((group) => !this.$store.state.user.tour_finished.includes(group))
                }
              }
            }
          }

        })
      }

    }
  }
};
</script>

<template>
  <div v-if="groups.length>0 && this.$store.state?.user?.tour_enabled">
    <div v-for="(group, index) in this.groups" :key="index">
      <VueTour :group="group" :steps="steps[group]"/>
    </div>
  </div>
</template>