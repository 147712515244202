<template>
  <PageLayout v-if="assessment">
    <template #back-navigation>
      <BackLink to="assessment-list" :label="$t('plannedAssessments')"></BackLink>
    </template>
    <template #content-header>{{ assessment.title }}</template>
    <template #content-header-suffix>{{ $helper.formatDate(assessment.dueDate) }}</template>
    <template #content>
      <div class="container">
        <AssessmentHelp :assessment="assessment"></AssessmentHelp>
      </div>
    </template>
    <template #content-footer>
      <PrimaryButton @click="continueClicked">{{ $t('continue') }}</PrimaryButton>
    </template>
  </PageLayout>

</template>

<script>

import PageLayout from "../backend/PageLayout.vue";
import BackLink from "../../components/BackLink.vue";
import AssessmentHelp from "@/components/AssessmentHelp.vue";

export default {
  name: 'assessment-intro',
  components: {AssessmentHelp, BackLink, PageLayout},
  data() {
    return {
      assessment: null,
      assessee: null,
    };
  },
  created() {
    this.$store.commit('setLoading', 'loading');
    this.$http.post('assessments/' + this.$route.params.assessmentId + '/state/review')
        .then(() => {
          this.getAssessment();
          this.$store.commit('setLoading', 'success');
        })


  },
  methods: {
    continueClicked() {
      this.$store.commit('setLoading', 'loading');

      this.$router.push({
        name: 'assessment-home',
        params: {assessmentId: this.$route.params.assessmentId, assesseeId: this.$route.params.assesseeId},
      })
          .catch(err => {
            this.$store.commit('setLoading', 'success');
            this.$toast.add({
              severity: 'error',
              summary: 'Asessment nicht abgeschlossen',
              detail: err,
              life: 3000,
            });
          });
    },
    getAssessment() {
      this.$store.dispatch('getAssessment', {id: this.$route.params.assessmentId}).then((res) => {
        this.assessment = res.data
        this.loading = false
      })
    }
  },
};
</script>
