import { render, staticRenderFns } from "./AbilitySlider.vue?vue&type=template&id=56b0be48"
import script from "./AbilitySlider.vue?vue&type=script&lang=js"
export * from "./AbilitySlider.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports