<template>
  <PageLayout>
    <template #content-header>
      {{ user.name }}
    </template>
    <template #content-header-suffix>
      {{ $moment(user.birth_date).format('DD.MM.YYYY') }}
    </template>
    <template #content>
      <div class="text-center pl-4 pr-4 container">
        <div class="row">
          <div class="col-sm-12 text-center mt-3">
            <UserImage :src="user.user_image" @click="toggleShowImageUpload" icon="pi pi-avatar"
                       class="cursor-pointer"></UserImage>
          </div>
          <div class="col-12 mt-1 text-white">{{ $t('email') }}: {{ user.email }}</div>
        </div>
        <div class="row mt-4 align-items-center">
          <div class="col-6 text-left">
            <strong>{{ $t('employeeNumber') }}</strong>
          </div>
          <div class="col-6 text-right">
            <label>{{ user.employee_number }}</label>
          </div>
        </div>
        <div class="row mt-4 align-items-center">
          <div class="col-6 text-left">
            <strong>{{ $t('enableNotifications') }}</strong>
          </div>
          <div class="col-6 text-right">
            <InputSwitch v-model="enableNotifications"/>
          </div>
        </div>
        <div class="row mt-4 align-items-center">
          <div class="col-6 text-left">
            <strong>{{ $t('showTicks') }}</strong>
          </div>
          <div class="col-6 text-right">
            <InputSwitch v-model="user.show_ticks" @change="updateUser()"/>
          </div>
        </div>
        <div class="row mt-4 align-items-center language-setting">
          <div class="col-6 text-left v-center">
            <strong>{{ $t('changeLocale') }}</strong>
          </div>
          <div class="col-6 text-right">

            <v-select :options="langs" v-model="userLanguage" @input="languageChanged($event)"></v-select>


          </div>
        </div>
        <div class="row mt-4 align-items-center">
          <div class="col-6 text-left">
            <strong>{{ $t('Reset password') }}</strong>
          </div>
          <div class="d-flex justify-content-end align-items-end w-50">
            <PrimaryButton @click="resetPasswordClicked">{{ $t('Reset') }}</PrimaryButton>

          </div>
        </div>
        <div v-if="$helper.hasAnyRole($roles.ADMIN)">
          <hr/>
          <h5 class="text-white">{{ $t('System Settings') }}</h5>
          <div class="row mt-4">
            <div class="col-6 text-left">
              <strong>{{ $t('Fallback Category') }}</strong><i v-tooltip="$t('Category for unassigned competences')"
                                                               class="pi pi-info"></i>
            </div>
            <div class="col-6 text-left">
              <PickerInput id="selected_category"
                           :title="$t('Select Category')"
                           :selected="selectedFallbackCategory.title"
                           :placeholder="$t('Select Category')"
                           v-model="selectedFallbackCategory.id"
                           :show-clear="false"
                           ref="fallback-category-picker">
                <TreeList :nodes="$store.state.categories"
                          @nodeSelected="fallbackCategorySelected"
                ></TreeList>
              </PickerInput>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6 text-left">
              <strong>{{ $t('Fallback HR Group') }}</strong><i v-tooltip="$t('HR Group for unassigned users')"
                                                               class="pi pi-info"></i>
            </div>
            <div class="col-6 text-left">
              <PickerInput id="selected_hr_group"
                           :title="$t('Select HR Group')"
                           :selected="selectedFallbackHRGroup.name"
                           :placeholder="$t('Select HR Group')"
                           v-model="selectedFallbackHRGroup.id"
                           :show-clear="false"
                           ref="fallback-hr-group-picker">
                <TreeList :nodes="$store.state.hrGroups"
                          @nodeSelected="fallbackHRGroupSelected"
                          :column-field="'name'"
                          :show-sub-nodes="true"
                ></TreeList>
              </PickerInput>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6 text-left">
              <strong>{{ $t('Fallback job role group') }}</strong><i
                v-tooltip="$t('Job role group for unassigned job roles')" class="pi pi-info"></i>
            </div>
            <div class="col-6 text-left">
              <PickerInput id="selected_job_role_group"
                           :title="$t('Select job role group')"
                           :selected="selectedFallbackJobRoleGroup.name"
                           :placeholder="$t('Select job role group')"
                           v-model="selectedFallbackJobRoleGroup.id"
                           :show-clear="false"
                           ref="fallback-job-role-group-picker">
                <TreeList :nodes="$store.state.jobRoleGroups"
                          @nodeSelected="fallbackJobRoleGroupSelected"
                          :column-field="'name'"
                          :show-sub-nodes="true"
                ></TreeList>
              </PickerInput>
            </div>
          </div>
        </div>
         <div class="row mt-4 align-items-center">
          <div class="col-6 text-left">
            <strong>{{ $t('Show tour') }}</strong>
          </div>
          <div class="col-6 text-right">
            <InputSwitch v-model="user.tour_enabled" @change="updateTour()"/>
          </div>
        </div>
        <div class="row mt-4 align-items-center" v-if="user.tour_finished && user.tour_enabled">
          <div class="col-6 text-left">
            <strong>{{ $t('Reset tour') }}</strong>
          </div>
          <div class="d-flex justify-content-end align-items-end w-50">
            <PrimaryButton @click="resetTourClicked">{{ $t('Reset') }}</PrimaryButton>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6 text-left">
            <strong>{{ $t('Version') }}</strong>
          </div>
          <div class="col-6 text-right">
            <label>{{ version }}</label>
          </div>
        </div>
      </div>
      <Dialog :visible.sync="showImageUpload" :style="{width: '350px'}" :modal="true" ref="image">
        <template #header>
          {{ $t('Upload image') }}
        </template>
        <div class="confirmation-content">
          <input type="file" accept="image/png, image/jpeg" ref="userImage" @change="uploadUserImage"/>
        </div>
      </Dialog>
    </template>
  </PageLayout>

</template>
<style>
.language-setting .vs__open-indicator {
  position: relative;
  left: 0;
}
</style>

<script>
import UserImage from '@/components/UserImage';
import PickerInput from "@/components/PickerInput";
import TreeList from "@/components/TreeList";
import PageLayout from "../backend/PageLayout.vue";
import {each} from "chart.js/helpers";

export default {
  name: 'home',
  components: {
    PageLayout,
    UserImage,
    PickerInput,
    TreeList
  },
  created: function () {
    this.$router;
    this.userLanguage = this.langs.find((lang) => {
      return lang.code === this.user.language
    });
    if (this.$helper.hasAnyRole([this.$roles.ADMIN])) {
      this.$store.dispatch('getCategories')
      this.$store.dispatch('getHrGroups')
      this.$store.dispatch('getJobRoleGroups')
      this.getSettings()
    }
  },
  data() {
    return {
      user: this.$store.getters.user,
      userLanguage: null,
      enableNotifications: false,
      version: process.env.VUE_APP_VERSION,
      langs: this.$helper.getAvailableLanguages(),
      showImageUpload: false,
      selectedFallbackCategory: {
        id: null,
        title: null,
      },
      selectedFallbackHRGroup: {
        id: null,
        name: null,
      },
      selectedFallbackJobRoleGroup: {
        id: null,
        name: null,
      }
    };
  },
  methods: {
    getSettings() {
      this.$http.get('settings').then((res) => {
        this.selectedFallbackCategory = this.$store.state.categories.find((category) => {
          return category.id === res.data.fallback_category_id
        })
        this.selectedFallbackHRGroup = this.$store.state.hrGroups.find((hrGroup) => {
          return hrGroup.id === res.data.fallback_hr_group_id
        })
        this.selectedFallbackJobRoleGroup = this.$store.state.jobRoleGroups.find((jobRoleGroup) => {
          return jobRoleGroup.id === res.data.fallback_job_role_group_id
        })
      });
    },
    fallbackCategorySelected(category) {
      this.$http.put('/settings', {
        'fallback_category_id': category.id
      }).then(() => {
        this.selectedFallbackCategory = category
        this.$refs["fallback-category-picker"].closeDialog()
      })
    },
    fallbackHRGroupSelected(hrGroup) {
      console.log(hrGroup)
      this.$http.put('/settings', {
        'fallback_hr_group_id': hrGroup.id
      }).then(() => {
        this.selectedFallbackHRGroup = hrGroup
        this.$refs["fallback-hr-group-picker"].closeDialog()
      })
          .catch((error) => {
            this.$helper.showErrorToast(error)
          })
    },
    fallbackJobRoleGroupSelected(jobRoleGroup) {
      this.$http.put('/settings', {
        'fallback_job_role_group_id': jobRoleGroup.id
      }).then(() => {
        this.selectedFallbackJobRoleGroup = jobRoleGroup
        this.$refs["fallback-job-role-group-picker"].closeDialog()
      })
          .catch((error) => {
            this.$helper.showErrorToast(error)
          })
    },
    languageChanged(event) {
      this.$i18n.locale = event.code;
      this.user.language = event.code;
      console.log("posting ", this.user);
      this.$store.dispatch('postUser', this.user)
          .then((res) => {
            console.log("successfully posted User ", res)
          })
          .catch(err => {
            this.$toast.add({
              severity: 'error',
              summary: 'User nicht  aktualisiert',
              detail: err,
              life: 3000,
            });

          });
    },
    updateUser() {
      this.$store.dispatch('postUser', this.user);
    },
    updateTour(){
      if(!this.user.tour_enabled){
        each(this.$tours, (tour) => {
          tour.stop()
        })
      }

      this.updateUser();

    },
    resetPasswordClicked() {
      this.$http
          .post('/forgot-password', {'email': this.user.email})
          .then(() => {
            this.submitStatus = 'OK';
            this.$toast.add({
              severity: 'success',
              summary: this.$t('Password reset requested'),
              detail: this.$t('You will get an E-Mail with a link to reset your password.'),
              life: 3000,
            });
          })
          .catch((error) => {
            this.submitStatus = 'OK';
            this.$helper.showErrorToast(error, this.$t('Error in resetting password'))
          });
    },
    resetTourClicked() {
      this.$http
          .patch('/users/' + this.user.id+ '/reset-tour')
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: this.$t('Tour reset successfully'),
              detail: this.$t('The Tour will be shown again.'),
              life: 3000,
            });
            this.$store.dispatch('getUser', {}).then(() => {
              this.user = this.$store.getters.user
              this.$store.commit('setLoading', 'success');
              this.showImageUpload = false
            })
          })
          .catch((error) => {
            this.$helper.showErrorToast(error, this.$t('Error in resetting tour'))
          });
    },
    toggleShowImageUpload() {
      this.showImageUpload = !this.showImageUpload
    },
    uploadUserImage() {
      this.$store.commit('setLoading', 'loading');
      let data = new FormData();
      data.append('image', this.$refs.userImage.files[0]);
      this.$http.post('users/' + this.user.id + '/user-image', data, {'Content-Type': 'multipart/form-data'})
          .then(() => {

            this.$toast.add({
              severity: 'success',
              summary: this.$t('Image successfully uploaded'),
              detail: this.$t('Your User image was successfully uploaded'),
              life: 3000,
            });

            this.$store.dispatch('getUser', {}).then(() => {
              this.user = this.$store.getters.user
              this.$store.commit('setLoading', 'success');
              this.showImageUpload = false
            })

          })
          .catch((error) => {
            this.$store.commit('setLoading', 'success');
            this.$helper.showErrorToast(error, this.$t('Error in uploading', error))
          })
    }
  },
};
</script>
