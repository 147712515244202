<template>
  <PageLayout>
    <template #back-navigation><span></span></template>
    <template #content-header>
      {{ $t('Assessments') }}
    </template>
    <template #content-header-suffix>{{ $t('Administration') }}</template>
    <template #content>
      <div class="mx-auto w-100 px-10">
        <DataTable :value="assessments"
                   :scrollable="true"
                   :filters.sync="filters"
                   @row-click="assessmentSelected"
                   :rowHover="true"
                   :globalFilterFields="['title', 'name', 'email']"
                   :paginator="true"
                   :rows="20"
                   :scroll-height="'68vh'"
                   dataKey="id"
        >
          <template #empty>
            {{ $t('No assessments found') }}
          </template>
          <template #header>
            <div class="d-flex flex-column flex-md-row align-items-center">
            <div class="flex-grow-1">
                 <IconInput
                     v-model="filters['global'].value" :placeholder="$t('Search')"
                     type="text"
                     icon="pi pi-search"
                     :clear="true"
                 />
            </div>

              <InputSwitch v-model="showCompleted" class="mx-2" @change="filterChanged()"/>
              <small>{{ $t('Show completed') }}</small>
              <InputSwitch v-model="showMyAssessments" class="mx-2" @change="filterChanged()"/>
              <small>{{ $t('My assessments') }}</small>
            </div>
          </template>
          <Column field="title" :header="$t('Title')" sortable :headerStyle="{'min-width':'20%'}"
                  :bodyStyle="{'min-width':'20%'}">
          </Column>
          <Column field="name" :header="$t('Assessee')" sortable :headerStyle="{'min-width':'20%'}"
                  :bodyStyle="{'min-width':'20%'}">
            <template #body="slotProps">
              <UserColumn :name="slotProps.data.name" :img="slotProps.data.assessee_image"/>
            </template>
          </Column>
          <Column field="email" :header="$t('Assessee Email')" sortable :headerStyle="{'min-width':'20%'}"
                  :bodyStyle="{'min-width':'20%'}">
          </Column>
          <Column field="assessor" :header="$t('Assessor')" sortable :headerStyle="{'min-width':'20%'}"
                  :bodyStyle="{'min-width':'20%'}">
            <template #body="slotProps">
              <UserColumn :name="slotProps.data.assessor" :img="slotProps.data.assessor_image"/>
            </template>
          </Column>

          <Column field="due_date" :header="$t('Due date')" sortable>
            <template #body="slotProps">
              <div>{{ $helper.formatDate(slotProps.data.due_date) }}</div>
            </template>
          </Column>
          <Column field="state" :header="$t('state')" sortable>
            <template #body="slotprops">
               <AssessmentStateColumn :data="slotprops.data"/>
            </template>
          </Column>

        </DataTable>
      </div>
    </template>
    <template #content-footer>
      <PrimaryButton @click="addAssessment">
        {{ $t('Add assessment') }}
      </PrimaryButton>
    </template>
  </PageLayout>


</template>

<style lang="scss">

.user-column {
  display: flex;
  align-items: center;

  .user-image img {
    width: 50px;
    height: 50px;
    margin: 0 2em;
  }

}
</style>

<script>
import PageLayout from "./PageLayout";
import {FilterMatchMode} from "primevue/api";
import IconInput from "../../components/IconInput.vue";
import AssessmentStateColumn from "../../components/DataTableColumns/AssessmentStateColumn.vue";
import UserColumn from "../../components/DataTableColumns/UserColumn.vue";

export default {
  name: 'assessmentsEditList',
  data() {
    return {
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      showCompleted: false,
      showMyAssessments: false,
      assessments: null
    }
  },
  created() {
    this.getAssessments();
  },
  methods: {
    addAssessment() {
      if (this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE, this.$roles.EXECUTIVE])) {
        this.$router.push({name: 'backend.assessments.assessment'});
      }
    },
    getAssessments() {
      this.$store.dispatch('getAssessments').then((res) => {
        this.assessments = res.data.filter((assessment) => {
          return assessment.completed_date === null;
        })
      })

    },
    assessmentSelected(assessment) {
      if (this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE, this.$roles.EXECUTIVE])) {
        this.$router.push({name: 'backend.assessments.assessment', params: {id: assessment.data.id}});
      }
    },
    filterChanged() {
      this.assessments = this.$store.state.assessments
      if (this.showCompleted) {
        this.assessments = this.assessments.filter((assessment) => {
          return assessment.completed_date;
        })
      } else {
        this.assessments = this.assessments.filter((assessment) => {
          return assessment.completed_date === null;
        })
      }
      if (this.showMyAssessments) {
        this.assessments = this.assessments.filter((assessment) => {
          return assessment.assessor_id == this.$store.getters.user.id
        })
      }
    },
  },
  components: {
    UserColumn,
    AssessmentStateColumn,
    IconInput,
    PageLayout,
  }
}
</script>