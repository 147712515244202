<template>
  <div class="d-flex flex-column flex-grow-1" v-if="assessment">
    <template v-if="assessment.state === 'review_assessment'">
       <div
    >
      <p class="text-left">{{
          $t('commonAssessment.welcomeMsg',
              {assessee: assessment.assessee.first_name})
        }}</p>
      <div class="row align-items-center">
        <div class="col">
          <img class="w-10 mx-2" src="@/assets/icons/circle-claim.svg" alt="circle"/>
          {{ $t('desireSelf') }}
        </div>
        <div class="col-9 text-left">
          {{ $t('commonAssessment.introDesireSelf') }}
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <img class="w-10 mx-2" src="@/assets/icons/circle.svg" alt="circle"/>
          {{ $t('abilityCoordinated') }}
        </div>
        <div class="col-9 text-left">
          {{ $t('commonAssessment.outroAbilityCommon', {assessor: assessment.assessor.first_name}) }}
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <img class="w-10 mx-2" src="@/assets/icons/diamound-green.svg" alt="circle"/>
          {{ $t('requirementCurrent') }}
        </div>
        <div class="col-9 text-left">
          {{ $t('commonAssessment.outroRequirementCurrent', {assessor: assessment.assessor.first_name}) }}
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <img class="w-10 mx-2" src="@/assets/icons/diamound-blue.svg" alt="circle"/>
          {{ $t('requirementFuture') }}
        </div>
        <div class="col-9 text-left">
          {{ $t('commonAssessment.outroRequirementFuture', {assessor: assessment.assessor.first_name}) }}
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <span><i class="pi pi-flag-fill competence-flag flagged mx-2"></i>{{ $t('learn') }}</span>
        </div>
        <div class="col-9 text-left">
          {{ $t('commonAssessment.outroActionRequiredFlag', {assessor: assessment.assessor.first_name}) }}
        </div>
      </div>


    </div>
    </template>
    <template v-if="assessment.state === 'open_assessment'">
      <div class="d-flex flex-column flex-grow-1">
        <div
            class="mt-4"
        >
          <p>{{ $t('commonAssessment.welcomeMsg',
              {assessee:assessment.assessee.first_name}) }}</p>
          <div class="row align-items-center">
            <div class="col">
               <img class="w-10 mx-2" src="@/assets/icons/circle-claim.svg" alt="circle"/>
                {{$t('desireSelf')}}
            </div>
            <div class="col-9 text-left">
              {{ $t('commonAssessment.introDesireSelf') }}
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col">
               <img class="w-10 mx-2" src="@/assets/icons/triangle_ud.svg" alt="circle"/>
                {{$t('abilitySelf')}}
            </div>
            <div class="col-9 text-left">
              {{ $t('commonAssessment.introAbilitySelf') }}
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col">
               <img class="w-10 mx-2" src="@/assets/icons/triangle.svg" alt="circle"/>
                {{$t('abilityPublic')}}
            </div>
            <div class="col-9 text-left">
              {{ $t('commonAssessment.introAbilityPublic', {assessor:assessment.assessor.first_name}) }}
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col">
               <img class="w-10 mx-2" src="@/assets/icons/circle.svg" alt="circle"/>
                {{$t('abilityCoordinated')}}
            </div>
            <div class="col-9 text-left">
              {{ $t('commonAssessment.introAbilityCommon', {assessee:assessment.assessee.first_name}) }}
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-3">
               <DisabledButton disabled>{{$t('Add comment')}}</DisabledButton>
            </div>
            <div class="col-9 text-left">
              {{ $t('commonAssessment.introComment', {assessor:assessment.assessor.first_name}) }}
            </div>
          </div>


        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'assessment-help',
  props: {
    assessment: {
      type: Object,
      default: null
    }
  }
}
</script>