<template>
  <PageLayout>
    <template #content-header>
      {{ $t('myTeam') }}
    </template>
    <template #content>
      <div class="mx-auto w-100 px-10">
        <DataTable
            :value="users"
            data-key="id"
            :rowHover="true"
            :filters="filters"
            :loading="loading"
            v-if="!loading"
            :expandedRows.sync="expandedRows"
            columnResizeMode="fit"
            :paginator="true"
            :scrollable="true"
            :scroll-height="'75vh'"
            :rows="20"
        >
          <template #empty>{{ $t('publicassessment.noAssesseesFound') }}.</template>
          <template #header>
            <div class="table-header d-flex align-items-center">
                  <span class="p-input-icon-left flex-grow-1">
                      <IconInput
                          v-model="filters['global'].value" :placeholder="$t('Search')"
                          type="text"
                          icon="pi pi-search"
                          :clear="true"
                      />
                  </span>
            </div>
          </template>
          <Column field="name" :headerStyle="{'min-width': '80%'}" :bodyStyle="{'min-width': '80%'}" sortable>
            <template #header>
              <div class="">
                <span>{{ $t('name') }}</span>
              </div>

            </template>
            <template #body="slotProps">
              <UserColumn :name="slotProps.data.name" :img="slotProps.data.user_image"/>
            </template>
            <template #filter>
              <IconInput :placeholder="$t('Search')" icon="pi pi-search" v-model="filters['global']" clear
                         animate></IconInput>

            </template>
          </Column>
          <Column field="completed_assessment_count" :header="$t('conductedAssessments')" headerStyle="width: 100px">
            <template #body="slotProps">
              <div class="assessment-count mx-auto" @click="userClicked(slotProps.data)">
                <router-link
                    :to="{name:'completed-assessment-list', params:{searchString:slotProps.data.first_name+' '+slotProps.data.last_name}}">
                  <template v-if="slotProps.data.completed_assessment_count<=0">
                    <Badge :value="String(slotProps.data.completed_assessment_count)" severity="info"></Badge>
                  </template>
                  <template v-else>
                    <Badge :value="String(slotProps.data.completed_assessment_count)" severity="warning"></Badge>
                  </template>
                </router-link>
              </div>
            </template>
          </Column>
          <Column field="open_assessment_count" :header="$t('openAssessments')" headerStyle="width: 100px">
            <template #body="slotProps">
              <div class="assessment-count mx-auto" @click="userClicked(slotProps.data)">
                <router-link
                    :to="{name:'assessment-list', params:{searchString:slotProps.data.first_name+' '+slotProps.data.last_name}}">
                  <template v-if="slotProps.data.open_assessment_count<=0">
                    <Badge :value="String(slotProps.data.open_assessment_count)" severity="info"></Badge>
                  </template>
                  <template v-else>
                    <Badge :value="String(slotProps.data.open_assessment_count)" severity="warning"></Badge>
                  </template>
                </router-link>
              </div>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="user-details my-4">
              <div>
                <div class="row">
                  <div class="col-sm-6 pb-0 pt-0">
                    {{ $t('firstName') }}:
                    <b>{{ slotProps.data.first_name }}</b>
                  </div>
                  <div class="col-sm-6 pb-0 pt-0">
                    {{ $t('lastName') }}:
                    <b>{{ slotProps.data.last_name }}</b>
                  </div>
                  <div class="col-sm-6 pb-0 pt-0">
                    {{ $t('email') }}:
                    <b>{{ slotProps.data.email }}</b>
                  </div>
                  <div class="col-sm-6 pb-0 pt-0">
                    {{ $t('employeeNumber') }}:
                    <b>{{ slotProps.data.employee_number }}</b>
                  </div>
                  <div class="col-sm-6 pb-0 pt-0" v-if="slotProps.data.job_position">
                    {{ $t('currentJob') }}:
                    <b>{{ slotProps.data.job_position.title }}</b>
                  </div>
                  <div class="col-sm-6 pb-0 pt-0" v-if="slotProps.data.future_job_position">
                    {{ $t('Future job position') }}:
                    <b>{{ slotProps.data.future_job_position.title }}</b>
                  </div>
                  <div class="col-12" v-for="assessment of slotProps.data.assessments" :key="assessment.id">
                    <hr>
                    <div class="row executive">
                      <div class="col-4 assessment-title">
                        {{ assessment.title }}
                      </div>
                      <div class="col-4">
                        <div class="progress-wrapper"
                             v-if="assessment.self_progress>=100 && assessment.public_progress >=100">
                          <small class="self-assessment-progress-label mr-3">{{ $t('selfImageShort') }}</small>
                          <ProgressBar class="common" :value="JSON.stringify(Math.round(assessment.common_progress))"
                                       :showValue="true"/>
                        </div>
                        <div class="progress-wrapper">
                          <small class="self-assessment-progress-label mr-3">{{ $t('selfImageShort') }}</small>
                          <ProgressBar class="self" :value="JSON.stringify(Math.round(assessment.self_progress))"
                                       :showValue="true"/>
                        </div>
                        <div class="progress-wrapper" v-bind:class="{'active':assessment.public_progress<100}">
                          <small class="public-assessment-progress-label mr-3">{{ $t('publicImageShort') }}</small>
                          <ProgressBar class="public" :value="JSON.stringify(Math.round(assessment.public_progress))"
                                       :showValue="true"></ProgressBar>
                        </div>
                      </div>
                      <div class="col-2">
                        <button
                            class="btn btn-primary rounded-pill rate-button"
                            @click="publicAssessmentClicked(assessment)"
                        >{{ $t('rate') }}
                        </button>
                      </div>
                      <div class="col-2">
                        <div class="start-assessment-wrapper">
                          <button
                              class="start-assessment-button"
                              v-bind:class="{ 'disabled': assessment.public_progress<100||assessment.self_progress<100}"
                              :disabled="assessment.public_progress<100||assessment.self_progress<100"
                              @click="startAssessmentClicked(assessment.id, assessment.assessee_id)"
                          ><i class="pi pi-chevron-right"></i>
                          </button>
                        </div>

                      </div>
                    </div>

                  </div>


                </div>

                <div class="list-footer d-flex flex-column justify-content-center align-items-center"
                     v-if="slotProps.data.completed_assessment_count>0">
                  <router-link
                      :to="{name:'completed-assessment-list', params:{searchString:slotProps.data.first_name+' '+slotProps.data.last_name}}">
                    {{ $t('toConductedAssessments') }}
                  </router-link>


                </div>


              </div>
            </div>
          </template>
        </DataTable>
      </div>
    </template>
  </PageLayout>
</template>

<script>
import IconInput from "@/components/IconInput";
import PageLayout from "../backend/PageLayout.vue";
import {FilterMatchMode} from "primevue/api";
import UserColumn from "../../components/DataTableColumns/UserColumn.vue";

export default {
  name: 'publicassessment-list',
  data() {
    return {
      users: null,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      loading: false,
      expandedRows: []
    };
  },
  mounted() {
    this.loading = true;
    this.getMyGroup();
  },
  methods: {
    checkDate(assessmentDate) {
      return this.$moment(assessmentDate).isSameOrBefore(this.$moment());
    },
    getMyGroup() {
      this.$store
          .dispatch('getMyTeam')
          .then(res => {
            this.users = res.data;
            this.loading = false;
          })
          .catch(err => {
            this.$toast.add({
              severity: 'error',
              summary: 'Mitarbeitender nicht geladen',
              detail: err,
              life: 3000,
            });
          });
    },
    publicAssessmentClicked(assessment) {
      console.log("Assessment ", assessment.id)
      this.$router.push({name: 'public-assessment-home', params:{assessmentId: assessment.id}});
    },
    startAssessmentClicked(assessmentId, assesseeId) {
      this.$router.push({
        name: 'assessment-home',
        params: {assessmentId, assesseeId},
      });
    },
    userClicked(user) {
      if (!this.expandedRows.includes(user)) {
        this.expandedRows = [];
        this.expandedRows.push(user);
      } else {
        this.expandedRows = [];
      }
    },
  },
  components: {
    UserColumn,
    PageLayout,
    IconInput,
  },
};
</script>

<style lang="scss">
.assessment-count {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.assessment-title {
  align-self: center;
}
</style>
