<script>
export default {
  name: 'VueTour',
  data() {
    return {
      callbacks: {
        onFinish: this.onFinish,
      },
      options: {
        startTimeout: 2000,
        labels: {
          buttonSkip: this.$t('Skip tour'),
          buttonPrevious: this.$t('back'),
          buttonNext: this.$t('continue'),
          buttonStop: this.$t('complete'),
        }
      },
    }
  },
  methods: {
    onFinish() {
      this.$store.dispatch('postUser', {tour_finished: this.group})
    },
    startTour() {
      this.$nextTick(() => {
        this.$tours['tour' + this.group].stop();
        this.$tours['tour' + this.group].start();
      })
    }
  },
  created() {
    this.startTour()
  },
  watch: {
    '$route': function () {
      this.startTour()
    }
  },
  props: {
    group: {
      type: String,
      default: 'general'
    },
    steps: {
      type: Array,
      default: null,
      required: true
    }
  }
};
</script>

<template>
  <div v-if="steps">
    <v-tour :name="'tour' + group" :steps="steps" :callbacks="callbacks" :options="options"></v-tour>
  </div>
</template>