<template>
  <div class="d-block d-md-flex w-100 bg-dark" v-if="this.competences">
    <portal to="sticky-header">
      <div class="w-100 mb-n6 mt-4"
           :style="{'width': this.getHeaderWidth()+'px !important', 'margin-left':this.getHeaderMarginLeft()+'px !important'}">
        <div class="d-flex flex-row">
          <div class="w-25 text-center text-white h5 m-0 border-right">
            {{ $t('novice') }}
          </div>
          <div class="w-25 text-center text-white h5 m-0 border-right">
            {{ $t('adept') }}
          </div>
          <div class="w-25 text-center text-white h5 m-0 border-right">
            {{ $t('master') }}
          </div>
          <div class="w-25 text-center text-white h5 m-0 border-right">
            {{ $t('expert') }}
          </div>
        </div>
        <div class="d-flex flex-row">
          <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center">
            {{ $t('knowing') }}
          </div>
          <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center border-right">
            {{ $t('doing') }}
          </div>
          <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center">
            {{ $t('knowing') }}
          </div>
          <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center border-right">
            {{ $t('doing') }}
          </div>
          <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center">
            {{ $t('knowing') }}
          </div>
          <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center border-right">
            {{ $t('doing') }}
          </div>
          <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center">
            {{ $t('knowing') }}
          </div>
          <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center border-right">
            {{ $t('doing') }}
          </div>
        </div>
      </div>
    </portal>

    <div id="competence-grid-table">
      <DataTable
          :value="this.filteredCompetences"
          :rowHover="true"
          :filters.sync="filters"
          ref="competence-table"
          filterDisplay="menu"
          :autoLayout="true"
          responsiveLayout="scroll"
          :lazy="true"
          @filter="onFilter"
          @sort="onSort"
          @state-restore="onStateRestore"
          :state-storage="'session'"
          :state-key="stateKey"
          removableSort
      >
        <template #header>
          <div class="table-header d-flex align-items-center">
            <span class="p-input-icon-left flex-grow-1">
              <IconInput
                  v-model="filters['global'].value" :placeholder="$t('Search')"
                  type="text"
                  @input="onFilter"
                  icon="pi pi-search"
                  :clear="true"
              />
            </span>
          </div>
        </template>
        <template #empty>{{ $t('No competences found') }}.</template>
        <Column field="title" :header="$tc('Competence')" sortable
                :showFilterMatchModes="false"
                :filterMenuStyle="{'max-width': '500px', 'max-height':'900px', 'background-color':'#1b242b'}"
                filterField="competence_data">

          <template #body="slotProps">
            <div class="d-flex align-items-center">
              <div :id="'competence_'+slotProps.data.id" class="position-relative pl-2 flex-grow-1"
                   @click="competenceSelected(slotProps.data)">
              <span class="category-indicator"
                    v-if="slotProps.data.category_color"
                    :style="'background:#'+slotProps.data.category_color"
              ></span>
                <small class="d-block">
                  <v-clamp :max-lines="1" :location="'middle'">
                    <template v-if="slotProps.data.category_breadcrumbs">
                      <template v-for="category in slotProps.data.category_breadcrumbs">
                        {{ category }} >
                      </template>
                    </template>
                    {{ slotProps.data.category_title }}
                  </v-clamp>
                </small>
                {{ slotProps.data.title }}
              </div>
              <div>
                <i v-if="slotProps.data[activeKey] > 0" class="pi pi-check-circle text-success"></i>
              </div>
            </div>


          </template>

          <template #filter="{filterModel}">
            <div class="d-flex flex-row align-items-center">
              <label class="flex-grow-1 mb-0">{{
                  filterModel.category_title.value ?? $t('Select Category')
                }}
              </label>
              <div>
                <button @click="()=>{showFilterCategories = !showFilterCategories}" type="button" aria-haspopup="true"
                        class="p-column-filter-menu-button p-link mx-2">
                  <span class="pi pi-filter-icon pi-filter text-white"></span>
                </button>
                <template v-if="filterModel.category_title.value">
                  <button @click="()=>{filterModel.category_title.value=null}" type="button" aria-haspopup="true"
                          class="p-column-filter-menu-button p-link mx-2">
                    <span class="pi pi-filter-icon pi-filter-slash text-white"></span>
                  </button>
                </template>
              </div>
            </div>

            <div v-if="showFilterCategories" class="overflow-auto" :style="{ 'max-height':'300px'}">
              <TreeList :nodes="categories"
                        @nodeSelected="(category)=>{
                          filterModel.category_title.value=category.title;
                          showFilterCategories=false;
                        }"
              ></TreeList>
            </div>
            <template v-if="showActionRequired">
              <hr class="bg-white my-2"/>
              <div class="d-flex flex-row align-items-center">
                <label class="flex-grow-1 mb-0">{{ $t('Show only flagged') }}
                </label>
                <div>
                  <InputSwitch v-model="filterModel.action_required.value"></InputSwitch>
                </div>
              </div>
            </template>
            <template v-if="showComment">
              <hr class="bg-white my-2"/>
              <div class="d-flex flex-row align-items-center">
                <label class="flex-grow-1 mb-0">{{ $t('Show only competences with comments') }}
                </label>
                <div>
                  <InputSwitch v-model="filterModel.comment.value"></InputSwitch>
                </div>
              </div>
            </template>

            <template v-if="activeKey">
              <hr class="bg-white my-2"/>
              <div class="d-flex flex-row align-items-center">
                <label class="flex-grow-1 mb-0">{{ $t('Hide rated') }}
                </label>
                <div>
                  <InputSwitch v-model="filterModel.hide_rated.value"></InputSwitch>
                </div>
              </div>

              <hr class="bg-white my-2"/>
              <div class="d-flex flex-column">
                <label class="flex-grow-1 mb-0">{{ $t('Filter rating') }}
                </label>
                <div class="flex-column mt-1">
                  <div class="d-flex d-flex-row justify-content-between text-white font-weight-lighter">
                    <div class="w-10">{{ $t('None') }}</div>
                    <div class="ml-3 ml-sm-1">{{ $t('novice') }}</div>
                    <div>{{ $t('adept') }}</div>
                    <div>{{ $t('master') }}</div>
                    <div>{{ $t('expert') }}</div>
                  </div>
                  <div class="d-flex">
                    <Slider v-model="filterModel.ability.value" :range="true" class="mx-3 mt-3" :min="0"
                            :max="4"></Slider>
                  </div>

                </div>
              </div>

            </template>

          </template>
          <template #filterapply="{filterCallback}">
            <div class="d-block d-md-flex mx-auto justify-content-between">
              <SecondaryButton class="mr-2" type="button" icon="pi pi-check" @click="onClear">{{ $t('Clear') }}
              </SecondaryButton>
              <PrimaryButton type="button" icon="pi pi-check" @click="filterCallback()">{{
                  $t('Apply')
                }}
              </PrimaryButton>
            </div>

          </template>
          <template #filterclear>

          </template>
        </Column>
        <template v-if="showComment">
          <Column field="comment">
            <template #body="slotProps">
              <div @click="$emit('commentSelected', slotProps.data)" class="text-center">
                <i
                    v-if="slotProps.data.comment"
                    class="pi pi-comment float-right competence-comment available"></i>
                <i v-else class="pi pi-comment float-right competence-comment"></i>
              </div>
            </template>
          </Column>
        </template>
        <template v-if="showActionRequired">
          <Column field="action_required" header="">
            <template #body="slotProps">
              <div @click="$emit('competenceFlagged', slotProps.data)">
                <i
                    v-if="slotProps.data.action_required"
                    class="pi pi-flag-fill float-right competence-flag flagged"></i>
                <i
                    v-if="!slotProps.data.action_required"
                    class="pi pi-flag float-right competence-flag"></i>
              </div>
            </template>
          </Column>
        </template>


      </DataTable>

    </div>
    <div v-if="this.filteredCompetences.length > 0" class="flex-grow-1 d-none d-md-block">
      <div :style="{'height': this.chartMarginTop+'px'}">
        <div class="d-flex flex-column justify-content-end h-100">
          <div class="competence-progress mx-2" v-if="completedPercent">
            <ProgressBar :value="completedPercent">{{ completedPercent }}%</ProgressBar>
          </div>
          <div class="d-flex align-items-center grow justify-content-center h5 text-white mt-2 mb-0" v-if="title">
            {{ title }} <span class="font-weight-lighter ml-1" v-if="subtitle">{{ subtitle }}</span>
          </div>
          <div class="d-flex align-items-end mb-2 mx-2 justify-content-between">
            <slot name="options"></slot>
          </div>

          <div id="competence-grid-header" class="d-flex flex-row">
            <div class="w-25 text-center text-white h5 m-0 border-right">
              {{ $t('novice') }}
            </div>
            <div class="w-25 text-center text-white h5 m-0 border-right">
              {{ $t('adept') }}
            </div>
            <div class="w-25 text-center text-white h5 m-0 border-right">
              {{ $t('master') }}
            </div>
            <div class="w-25 text-center text-white h5 m-0 border-right">
              {{ $t('expert') }}
            </div>
          </div>
          <div class="d-flex flex-row">
            <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center">
              {{ $t('knowing') }}
            </div>
            <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center border-right">
              {{ $t('doing') }}
            </div>
            <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center">
              {{ $t('knowing') }}
            </div>
            <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center border-right">
              {{ $t('doing') }}
            </div>
            <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center">
              {{ $t('knowing') }}
            </div>
            <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center border-right">
              {{ $t('doing') }}
            </div>
            <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center">
              {{ $t('knowing') }}
            </div>
            <div :style="{'width':'12.5%'}" class="font-weight-lighter text-white h6 m-0 text-center border-right">
              {{ $t('doing') }}
            </div>
          </div>
        </div>


      </div>
      <AssessmentChart
          :competencesProp="this.filteredCompetences.slice()"
          :show-ability="showAbility"
          :show-claim="showClaim"
          :show-public-ability="showPublicAbility"
          :show-common-ability="showCommonAbility"
          :show-current-job="showCurrentJob"
          :show-future-job="showFutureJob"
          :show-job-abilities="showJobAbilities"
          :height="height"
      ></AssessmentChart>
    </div>
  </div>


</template>

<script>

import AssessmentChart from "./AssessmentChart.vue";
import {FilterMatchMode} from "primevue/api";
import IconInput from "./IconInput.vue";
import TreeList from "./TreeList.vue";

export default {
  name: 'CompetenceGrid',
  components: {TreeList, IconInput, AssessmentChart},
  data() {
    return {
      filteredCompetences: [],
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'competence_data': {
          'category_title': {value: null, matchMode: FilterMatchMode.CONTAINS},
          'action_required': {value: null, matchMode: FilterMatchMode.EQUALS},
          'comment': {value: null, matchMode: FilterMatchMode.EQUALS},
          'hide_rated': {value: null, matchMode: FilterMatchMode.EQUALS},
          'ability': {value: null, matchMode: FilterMatchMode.EQUALS},
          'value': null
        }
      },
      height: null,
      chartMarginTop: null,
      categories: [],
      showFilterCategories: false,
      selectedFilterCategory: null,
    }
  },
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    competences: {
      type: Array,
      default: null,
    },
    showCurrentJob: {
      type: Boolean,
      default: false
    },
    showFutureJob: {
      type: Boolean,
      default: false
    },
    showJobAbilities: {
      type: Boolean,
      default: false
    },
    showPublicAbility: {
      type: Boolean,
      default: false
    },
    showCommonAbility: {
      type: Boolean,
      default: false
    },
    showAbility: {
      type: Boolean,
      default: false
    },
    showClaim: {
      type: Boolean,
      default: false
    },
    showActionRequired: {
      type: Boolean,
      default: false,
    },
    showComment: {
      type: Boolean,
      default: false,
    },
    activeKey: {
      type: String,
      default: null,
    },
    stateKey: {
      type: String,
      default: null,
    }
  },
  computed: {
    completedPercent() {
      if (this.activeKey) {
        return Math.round(this.competences.filter((competence) => competence[this.activeKey] > 0).length / this.competences.length * 100);
      }
      return null;
    },
  },
  methods: {
    onFilter(event) {
      this.filteredCompetences = this.competences

      if (typeof event === "string") {
        this.filteredCompetences = this.filteredCompetences.filter((competence) => competence.title.toLowerCase().includes(event.toLowerCase()) || competence.category_title.toLowerCase().includes(event.toLowerCase()))
        event.filters.competence_data.value = 'filter-set'
      } else {
        event.filters.competence_data.value = null
      }


      if (event.filters.competence_data.category_title.value) {
        this.filteredCompetences = this.filteredCompetences.filter((competence) => competence.category_title === event.filters.competence_data.category_title.value)
        event.filters.competence_data.value = 'filter-set'
      }
      if (event.filters.competence_data.action_required.value) {
        this.filteredCompetences = this.filteredCompetences.filter((competence) => competence.action_required === event.filters.competence_data.action_required.value)
        event.filters.competence_data.value = 'filter-set'
      }
      if (event.filters.competence_data.comment.value) {
        this.filteredCompetences = this.filteredCompetences.filter((competence) => competence.comment !== null)
        event.filters.competence_data.value = 'filter-set'
      }
      if (event.filters.competence_data.hide_rated.value) {
        this.filteredCompetences = this.filteredCompetences.filter((competence) => competence[this.activeKey] <= 0)
        event.filters.competence_data.value = 'filter-set'
      }
      if (event.filters.competence_data.ability.value) {
        this.filteredCompetences = this.filteredCompetences
            .filter((competence) => (competence[this.activeKey] >= ((event.filters.competence_data.ability.value[0] * 2) - 1) && competence[this.activeKey] <= (event.filters.competence_data.ability.value[1] * 2)))
        event.filters.competence_data.value = 'filter-set'
      }
    },
    onStateRestore(event) {
      setTimeout(() => {
        this.onFilter(event)
      }, 50);
    },
    onSort(event) {
      if (event.sortOrder) {
        this.filteredCompetences.sort((a, b) => {
          const titleA = a.title.toUpperCase();
          const titleB = b.title.toUpperCase();
          if (titleA > titleB) {
            return event.sortOrder;
          }
          if (titleA < titleB) {
            return event.sortOrder * -1;
          }
          return 0;
        })
      } else {
        this.onStateRestore(event)
      }

    },
    onClear() {
      this.initFilters()
      this.filteredCompetences = this.competences.slice()
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'competence_data': {
          'category_title': {value: null, matchMode: FilterMatchMode.CONTAINS},
          'action_required': {value: null, matchMode: FilterMatchMode.EQUALS},
          'comment': {value: null, matchMode: FilterMatchMode.EQUALS},
          'hide_rated': {value: null, matchMode: FilterMatchMode.EQUALS},
          'ability': {value: null, matchMode: FilterMatchMode.BETWEEN},
          'value': null,
        }
      }
    },
    async competenceSelected(competence) {
      setTimeout(() => {
        this.$emit('competenceSelected', competence)
      }, 50);
    },
    getHeaderWidth() {
      if(document.querySelector("#competence-grid-header")){
        return document.querySelector("#competence-grid-header").getBoundingClientRect()
          .width;
      }
      return 0;

    },
    getHeaderMarginLeft() {
      if(document.querySelector("#competence-grid-header")){
         return document.querySelector("#competence-grid-table").getBoundingClientRect()
          .width - 3;
      }
    return 0;
    },
  },
  mounted() {
    this.$store.dispatch('getCategories').then((res) => {
      this.categories = res.data
      if (this.$route.params.currentCompetence) {
        document.getElementById('competence_' + this.$route.params.currentCompetence).scrollIntoView({behavior: "smooth"});
      }
    })
    this.filteredCompetences = this.competences.slice()
  },
  updated() {
    let datatableHeader = parseFloat(window.getComputedStyle(document.querySelector('#competence-grid-table .p-datatable-header')).height)
    let headerRow = parseFloat(window.getComputedStyle(document.querySelectorAll('#competence-grid-table tr')[0]).height)
    let tableHeaderHeight = datatableHeader + headerRow
    this.height = Math.round(parseFloat(window.getComputedStyle(document.querySelector('#competence-grid-table .p-datatable-tbody')).height))
    this.chartMarginTop = tableHeaderHeight

  },
}

</script>

<style lang="scss">
.competence-flag.flagged {
  color: $danger;
}

.competence-flag:hover {
  color: $danger;
}

#competence-grid-table .competence-flag.flagged:hover {
  color: $lighter;
}

.competence-comment {
  color: $gray;
}

.competence-comment.available {
  color: $primary;
}

.competence-comment:hover {
  color: $primary;
}

.competence-comment.available:hover {
  color: $primary;
  font-weight: bolder;
}

body .competence-progress .p-progressbar .p-progressbar-value {
  background: $primary !important;
}
</style>