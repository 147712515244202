<template>
  <PageLayout>
    <template #back-navigation><span></span></template>
    <template #content-header>
      {{ $t('Assessments') }}
    </template>
    <template #content-header-suffix>{{ (showCompleted) ? $t('conducted') : $t('open') }}</template>
    <template #content>
      <div class="mx-auto w-100 px-10">
        <DataTable :value="assessments"
                   data-key="id"
                   :filters.sync="filters"
                   :paginator="true"
                   :scrollable="true"
                   :rows="20"
                   :scroll-height="'75vh'">
          <template #empty>{{ $t('noAssessmentsFound') }}.</template>
          <template #header>
            <div class="d-flex flex-column flex-md-row align-items-center">
              <div class="flex-grow-1 mx-2">
                <IconInput
                    v-model="filters['global'].value" :placeholder="$t('Search')"
                    type="text"
                    icon="pi pi-search"
                    :clear="true"
                />
              </div>

              <small>{{ $t('Open assessments') }}</small>
              <InputSwitch v-model="showCompleted" class="mx-2" @change="filterChanged()"/>
              <small>{{ $t('Conducted assessments') }}</small>
            </div>
          </template>
          <Column field="title" :header="$t('Title')" sortable :headerStyle="{'min-width':'10%'}"
                  :bodyStyle="{'min-width':'10%'}">
          </Column>
          <Column field="name" :header="$t('Assessee')" sortable :headerStyle="{'min-width':'10%'}"
                  :bodyStyle="{'min-width':'10%'}">
            <template #body="slotProps">
              <UserColumn :name="slotProps.data.name" :img="slotProps.data.assessee_image"/>
            </template>
          </Column>
          <Column field="assessor" :header="$t('Assessor')" sortable :headerStyle="{'min-width':'10%'}"
                  :bodyStyle="{'min-width':'10%'}">
            <template #body="slotProps">
              <UserColumn :name="slotProps.data.assessor" :img="slotProps.data.assessor_image"/>
            </template>
          </Column>
          <Column field="due_date" :header="$t('Due date')" :headerStyle="{'min-width':'5%'}"
                  :bodyStyle="{'min-width':'5%'}" sortable>
            <template #body="slotProps">
              <div>{{ $helper.formatDate(slotProps.data.due_date) }}</div>


            </template>
          </Column>
          <Column :hidden="!showCompleted" field="completed_date" :header="$t('conducted')"
                  :headerStyle="{'min-width':'5%'}"
                  :bodyStyle="{'min-width':'5%'}" sortable>
            <template #body="slotProps">
              <div v-if="slotProps.data.completed_date">{{ $helper.formatDate(slotProps.data.completed_date) }}</div>
            </template>
          </Column>
          <Column field="public_progress" :header="$t('progress %')"
                  :headerStyle="{'min-width':'40%'}" :bodyStyle="{'min-width':'40%'}">
            <template #body="slotProps">
              <div class="w-100 px-4"
                   v-bind:class="{'employee':!$helper.hasAnyRole($roles.EXECUTIVE), 'executive':$helper.hasAnyRole($roles.EXECUTIVE)}">
                <div class="progress-wrapper" v-bind:class="{'active':slotProps.data.self_progress<100}">
                  <small class="self-assessment-progress-label mr-3">{{ $t('selfImageShort') }}</small>
                  <ProgressBar class="self" :value="JSON.stringify(Math.round(slotProps.data.self_progress))"
                               :showValue="true"/>
                </div>
                <div class="progress-wrapper"
                     v-if="$helper.hasAnyRole($roles.EXECUTIVE) && user.id!=slotProps.data.assessee_id"
                     v-bind:class="{'active':slotProps.data.public_progress<100}">
                  <small class="public-assessment-progress-label mr-3">{{ $t('publicImageShort') }}</small>
                  <ProgressBar class="public" :value="JSON.stringify(Math.round(slotProps.data.public_progress))"
                               :showValue="true"></ProgressBar>
                </div>
                <div class="progress-wrapper"
                     v-if="$helper.hasAnyRole($roles.EXECUTIVE) && slotProps.data.self_progress>=100 && slotProps.data.public_progress >=100"
                     v-bind:class="{'active':slotProps.data.common_progress<100}">
                  <small class="common-assessment-progress-label mr-3">{{ $t('commonImageShort') }}</small>
                  <ProgressBar class="common" :value="JSON.stringify(Math.round(slotProps.data.common_progress))"
                               :showValue="true"/>
                </div>
              </div>


            </template>
          </Column>

          <Column
              :headerStyle="{'min-width': '5%'}"
              :header="$helper.hasAnyRole($roles.EXECUTIVE)?$t('publicImage') + ' ' + $t('selfImage'):$t('selfImage')"
              :bodyStyle="{'min-width':'5%'}"
              :hidden="showCompleted"
          >
            <template #body="slotProps">
              <div class="text-center mx-auto"
                   v-if="user.id!=slotProps.data.assessee_id && $helper.hasAnyRole($roles.EXECUTIVE)">
                <template v-if="slotProps.data.public_progress>=100">
                  <span class="font-weight-bold text-success text-uppercase">{{ $t('rated') }}</span>
                  <span class="edit-link d-block border-top pt-1 mt-1" style=""
                        @click="publicAssessmentClicked(slotProps.data)">
                                {{ $t('edit') }}
                                  </span>
                </template>
                <template v-else>
                  <button
                      class="btn btn-primary rounded-pill rate-button "
                      style="min-width:100px"
                      @click="publicAssessmentClicked(slotProps.data)"
                  >{{ $t('rate') }}
                  </button>
                </template>
              </div>
              <div v-else class="mx-auto">
                <template v-if="slotProps.data.self_progress>=100">
                  <span class="font-weight-bold text-success text-uppercase">{{ $t('rated') }}</span>
                  <span class="edit-link d-block border-top pt-1 mt-1" style=""
                        @click="selfAssessmentClicked(slotProps.data)">
                                {{ $t('edit') }}
                              </span>
                </template>
                <template v-else>
                  <button
                      class="btn btn-primary rounded-pill rate-button "
                      style="min-width:100px"
                      @click="selfAssessmentClicked(slotProps.data)"
                  >{{ $t('rate') }}
                  </button>
                </template>
              </div>
            </template>
          </Column>
          <Column field="state" :header="$t('state')" sortable :headerStyle="{'min-width':'5%'}"
                  :bodyStyle="{'min-width':'5%'}">
            <template #body="slotprops">
              <AssessmentStateColumn :data="slotprops.data"/>
            </template>
          </Column>

          <Column
              :header="$t('assessment')"
          >
            <template #body="slotProps">
              <div class="mx-auto text-center w-12">
                <button
                    class="start-assessment-button"
                    v-bind:class="{ 'disabled': slotProps.data.public_progress<100||slotProps.data.self_progress<100}"
                    :disabled="slotProps.data.public_progress<100||slotProps.data.self_progress<100"
                    @click="startAssessmentClicked(slotProps.data, slotProps.data.assessee_id)"
                ><i class="pi pi-chevron-right" style="font-size:32px;"></i>
                </button>
              </div>

            </template>
          </Column>
        </DataTable>
      </div>

    </template>
  </PageLayout>


</template>

<script>
import PageLayout from "../backend/PageLayout.vue";
import {FilterMatchMode} from "primevue/api";
import IconInput from "../../components/IconInput.vue";
import AssessmentStateColumn from "../../components/DataTableColumns/AssessmentStateColumn.vue";
import UserColumn from "../../components/DataTableColumns/UserColumn.vue";

export default {
  name: 'assessment-list',
  data() {
    return {
      user: this.$store.getters.user,
      assessments: null,
      showCompleted: false,
      filters: {
        'global': {value: this.$route.params.searchString, matchMode: FilterMatchMode.CONTAINS},
        'state': {value: null},
      },
      expandedRows: [],
      loading: false
    };
  },
  created() {
    this.loading = true;
    this.getMyAssessments();
  },
  methods: {
    getMyAssessments() {
      this.filterChanged()
    },
    publicAssessmentClicked(assessment) {
      this.$router.push({
        name: 'public-assessment-home',
        params: {assessmentId: assessment.id},
      });
    },
    selfAssessmentClicked(assessment) {
      this.$router.push({
        name: 'self-assessment-home',
        params: {assessmentId: assessment.id},
      });
    },
    startAssessmentClicked(assessment, assesseeId) {
      if (!assessment.completed_date) {
        this.$router.push({
          name: 'assessment-intro',
          params: {assessmentId: assessment.id, assesseeId},
        });
      } else {
        this.$router.push({
          name: 'assessment-summary',
          params: {assessmentId: assessment.id, assesseeId},
        });
      }

    },
    filterChanged() {
      this.$store
          .dispatch('getMyAssessments')
          .then((res) => {
            if (this.showCompleted) {
              this.assessments = res.data.filter((assessment) => {
                return assessment.completed_date;
              })
            } else {
              this.assessments = res.data.filter((assessment) => {
                return assessment.completed_date === null;
              })
            }
          })

    },
  },
  components: {
    UserColumn,
    AssessmentStateColumn,
    IconInput,
    PageLayout,
  },
};
</script>

<style lang="scss">
.edit-link:hover {
  color: $primary;
  text-decoration: underline;
}

body .assessment-list {
  .p-progressbar {
    background-color: $darker !important;
    height: 10px !important;
    margin: 5px;
    width: 100%;

    .p-progressbar-label {
      line-height: 10px;
      font-size: 0.6em;
      color: white;
    }
  }

  .p-datatable .p-datatable-thead > tr > th {
    background: transparent !important;
  }
}

.assessment-list {
  height: 80vh;
}

.list-footer {
  height: 7vh;
}

.title-wrapper {
  text-align: right;
}

.date-wrapper {
  text-align: right;
}

.user-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;


}

.progress-wrapper {
  display: flex;
  align-items: center;
  justify-content: left;

  .p-progressbar {
    width: 100%;
  }
}

.employee {
  .self.p-progressbar .p-progressbar-value {
    background: $light-gray !important;
  }

  .active {
    .self.p-progressbar .p-progressbar-value {
      background: $primary !important;
    }
  }

  .public.p-progressbar .p-progressbar-value {
    background: $light-gray !important;
  }

}

.executive {
  .self.p-progressbar .p-progressbar-value {
    background: $light-gray !important;
  }

  .public.p-progressbar .p-progressbar-value {
    background: $light-gray !important;
  }

  .active {
    .common.p-progressbar .p-progressbar-value {
      background: $primary !important;
    }

    .public.p-progressbar .p-progressbar-value {
      background: $primary !important;
    }
  }


}

.user-img-list {
  display: inline;
  margin-right: 1em;

  img {
    width: 50px !important;
    height: 50px !important;
  }
}

.user-line {
  background: $dark;
}

.public-assessment .btn-primary.rate-button {
  min-width: 0;
}

.start-assessment-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .start-assessment-button {
    color: $primary;
    background: none;
    border: none;
    line-height: 0;

    .pi {
      font-size: 2.5em;
    }
  }
}

.start-assessment-button.disabled {
  color: $light-gray;
  background: none;
  border: none;

  .pi {
    font-size: 2.5em;
  }
}

.table-container {
  background: none !important;
  box-shadow: none !important;
}

.public-assessment-progress-label {
  font-weight: bold;
  color: white;
  float: left;

}

.common-assessment-progress-label {
  font-weight: bold !important;
  color: white;
  text-align: center;
  text-transform: uppercase;
}

.self-assessment-progress-label {
  font-weight: bold;
  color: white;
  float: left;
}

.loading-info {
  color: red;
  font-weight: bold;
}

.name-header {
  display: flex;
  justify-content: center;
  align-content: center;

  span {
    position: absolute;
    margin-top: 1em;
  }

}

</style>
