<template>
  <div class="mx-auto">
    <h1 class="logo text-white text-center text-uppercase font-weight-bold align-middle page-title-home dashboard-title">
      Talent<span>eers</span> Backend
    </h1>
    <div class="container">
      <div class="row" v-if="user">
        <div class="col-sm-12 text-white text-center">
          <h1 class="dashboard-title">{{ $t('hello') }}, {{ this.user.first_name }}!</h1>
          <p style="font-weight:300" class="mb-0">
            <span v-for="(role, index) of this.user.roles" :key="role.name">{{ $t(role.name) }}<span
                v-if="user.roles.length>0 && index<user.roles.length-1"> | </span> </span>
          </p>
        </div>
        <div class="col-sm-12 text-center mb-3">
          <UserImage link="profile" :src="user.user_image"></UserImage>
        </div>
      </div>
      <div class="row">
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/competences/competence/info"
                       :title="$t('Competences')" @click="resetExpandedKeys"
                       :subtitle="this.publishedCompetencesCount + ' ' +this.$t('published') + ' | ' + this.allCompetencesCount + ' ' + this.$t('created')">
        ></DashboardLink>
        <DashboardLink
            v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE])"
            to="/backend/assessments/"
            :title="$t('Assessments')" @click="resetExpandedKeys"
            :subtitle="this.plannedAssessmentCount + ' ' + this.$t('planned')"></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/job-positions/job-position"
                       :title="$t('Job Positions')" @click="resetExpandedKeys"
        :subtitle="this.freeJobPositionsCount + ' ' + this.$t('free') + ' | ' + this.jobPositionsCount + ' ' + this.$t('available')"></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/job-role-groups/job-role"
                       :title="$t('Job Roles')" @click="resetExpandedKeys"
        :subtitle="this.jobRolesCount + ' ' + this.$t('available')"></DashboardLink>
        <DashboardLink
            v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE])"
            to="/backend/hr-groups/user"
            :title="$t('Users')" @click="resetExpandedKeys"
        :subtitle="this.activeUsersCount + ' ' + this.$t('active') + ' | ' + this.allUsersCount + ' ' + this.$t('created')"></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/tags/tag"
                       :title="$t('Tags')" @click="resetExpandedKeys"
         :subtitle="this.tagsCount + ' ' + this.$t('created') "></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/reporting"
                       :title="$t('Reporting')"></DashboardLink>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.dashboard-title {
  font-size: 1.5rem;
}
</style>
<script>
import DashboardLink from '@/components/DasboardLink';
import UserImage from "@/components/UserImage";

export default {
  name: 'dashboard',
  created() {
    this.$store.dispatch('getUser', {})
    this.$http.get('backend_dashboard_data')
        .then((res)=>{
          this.publishedCompetencesCount = res.data.published_competences
          this.allCompetencesCount = res.data.all_competences
          this.plannedAssessmentCount = res.data.planned_assessments
          this.freeJobPositionsCount = res.data.free_job_positions
          this.jobPositionsCount = res.data.all_job_positions
          this.jobRolesCount = res.data.job_roles
          this.allUsersCount = res.data.all_users
          this.activeUsersCount = res.data.active_users
          this.tagsCount = res.data.tags
        })
  },
  data() {
    return {
      publishedCompetencesCount: 0,
      allCompetencesCount: 0,
      plannedAssessmentCount: 0,
      freeJobPositionsCount: 0,
      jobPositionsCount: 0,
      jobRolesCount: 0,
      activeUsersCount: 0,
      allUsersCount: 0,
      tagsCount: 0,
    }
  },
  components: {
    DashboardLink,
    UserImage
  },
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    resetExpandedKeys() {
      localStorage.setItem('expandedKeys', [])
    },
  }
};
</script>
