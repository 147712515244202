<template>
  <div class="w-100">
    <portal to="content-header-right">
      <span class="rounded-pill p-2 ml-2 bg-danger text-white font-weight-bold"
            v-if="!user.email_verified_at && !user.deleted_at && $route.params.id">
        {{ this.$t('user not verified') }}
      </span>
      <span class="rounded-pill p-2 ml-2 bg-info text-white font-weight-bold" v-if="user.email_sent_at">
        {{ this.$t('Activation mail sent') }}
        <span class="font-weight-lighter">
          {{this.$helper.formatDate(user.email_sent_at) }}
        </span>
      </span>
      <span class="rounded-pill p-2 ml-2 bg-danger text-white font-weight-bold" v-if="!user.email_sent_at && !user.email_verified_at">
        {{ this.$t('Activation mail pending') }}
      </span>

      <span class="bg-danger rounded-pill text-white p-2" v-if="user.deleted_at && $route.params.id">
        {{ this.$t('inactive') }}
      </span>
    </portal>
    <form @submit.prevent="saveUser">
      <div class="row">
        <div class="col-12 form-group" :class="{ 'form-group--error': $v.user.first_name.$error }">
          <label for="first_name">{{ $t('First name') }}</label>
          <InputText id="first_name" type="text" v-model="user.first_name"/>
        </div>
        <div class="error" v-if="!$v.user.first_name.required">Pflichtfeld</div>
        <div class="error" v-if="!$v.user.first_name.maxLength">
          {{ this.$t('Only {maxLength} Characters allowed', {maxLength: 255}) }}
        </div>

        <div class="col-12 form-group" :class="{ 'form-group--error': $v.user.last_name.$error }">
          <label for="last_name">{{ $t('Last name') }}</label>
          <InputText id="last_name" type="text" v-model="user.last_name"/>
        </div>
        <div class="error" v-if="!$v.user.last_name.required">Pflichtfeld</div>
        <div class="error" v-if="!$v.user.last_name.maxLength">
          {{ this.$t('Only {maxLength} Characters allowed', {maxLength: 255}) }}
        </div>

        <div class="col-12 form-group" :class="{ 'form-group--error': $v.user.email.$error }">
          <label for="email">{{ $t('Email') }}</label>
          <InputText id="email" type="email" v-model="user.email"/>
        </div>
        <div class="error" v-if="!$v.user.email.required">{{this.$t('requiredField')}}</div>
        <div class="error" v-if="!$v.user.email.email">{{ this.$t('error.typeEmail') }}</div>
        <div class="col-12 form-group" :class="{ 'form-group--error': $v.user.employee_number.$error }">
          <label for="employee_number">{{ $t('Employee number') }}</label>
          <InputText id="employee_number" type="text" v-model="user.employee_number"/>
        </div>
        <div class="error" v-if="!$v.user.employee_number.required">Pflichtfeld</div>
        <div class="error" v-if="!$v.user.employee_number.numeric">{{ this.$t('Field has to be numeric') }}</div>

        <div class="col-12 form-group" :class="{ 'form-group--error': $v.user.birth_date.$error }">
          <label for="birth_date">{{ $t('Birth date') }}</label>
          <div class="d-block">
            <DatePicker v-model="user.birth_date"/>
          </div>

        </div>
        <div class="error" v-if="!$v.user.birth_date.required">Pflichtfeld</div>
        <div class="col-12 form-group" :class="{ 'form-group--error': $v.selectedJobPosition.title.$error }"
             v-if="!this.user.deleted_at">
          <label for="job-position">{{ $t('Job position') }}</label>
          <PickerInput id="job-position"
                       :title="$t('Free job positions')"
                       :placeholder="$t('Select job position')"
                       :selected="selectedJobPosition.title"
                       :show-clear="false"
                       :external-link="selectedJobPosition.id?'/backend/job-positions/job-position/'+selectedJobPosition.id:'/backend/job-positions/job-position/'"
                       @dialogOpened="getFunctionalGroupsWithFreejobPositions"
                       ref="job-position-picker">
            <TreeList v-if="functionalGroupsWithFreejobPositions"
                      :nodes="this.functionalGroupsWithFreejobPositions"
                      :show-sub-nodes="true"
                      column-field="name"
                      :custom-node-key="'jobPositions'"
                      :node-selectable="false"
                      @customNodeSelected="jobPositionSelected"
            ></TreeList>
            <p v-if="!functionalGroupsWithFreejobPositions">{{ $t('No free job positions found') }}</p>
          </PickerInput>
        </div>

        <div class="error" v-if="!$v.selectedJobPosition.title.required">Pflichtfeld</div>
        <div class="col-12 form-group">
          <label for="future-job-position">{{ $t('Future job position') }}</label>
          <PickerInput id="future-job-position"
                       :title="$t('Future job position')"
                       :placeholder="$t('Select future job position')"
                       :selected="selectedFutureJobPosition.title"
                       :show-clear="true"
                       :external-link="selectedFutureJobPosition.id?'/backend/job-positions/job-position/'+selectedFutureJobPosition.id:'/backend/job-positions/job-position/'"
                       ref="future-job-position-picker"
                       @dialogOpened="getFunctionalGroups"
                       @clearClick="selectedFutureJobPosition={}">
            <TreeList :nodes="this.functionalGroups"
                      :show-sub-nodes="true"
                      column-field="name"
                      :custom-node-key="'jobPositions'"
                      @customNodeSelected="futureJobPositionSelected"
            ></TreeList>
          </PickerInput>
        </div>
        <div class="col-12 form-group" :class="{ 'form-group--error': $v.selectedHrGroup.name.$error }">
          <label for="hr-group">{{ $t('HR Group') }}</label>
          <PickerInput id="hr-group"
                       :title="$t('HR Group')"
                       :placeholder="$t('Select HR Group')"
                       :selected="selectedHrGroup.name"
                       :show-clear="false"
                       ref="hr-group-picker">
            <TreeList :nodes="this.hrGroups"
                      :column-field="'name'"
                      :show-sub-nodes="true"
                      @nodeSelected="hrGroupSelected"
            ></TreeList>
          </PickerInput>
        </div>
        <div class="error" v-if="!$v.selectedHrGroup.name.required">Pflichtfeld</div>
        <div class="col-12 form-group">
          <label for="language">{{ $t('Language') }}</label>
          <PickerInput id="language"
                       :title="$t('Language')"
                       :placeholder="$t('Select language')"
                       :selected="selectedLanguage.label"
                       :showClear="false"
                       ref="language-picker">
            <TreeList :nodes="this.langs"
                      @nodeSelected="languageSelected"
                      :column-field="'label'"
            ></TreeList>
          </PickerInput>
        </div>

        <div class="col-12 form-group" v-if="$route.params.id && roles" id="user-roles">
          <label>{{ $t('User roles') }}</label>

          <CustomPickList :data="roles"
                          :column-field="'name'"
                          @move-to-target="addRole"
                          @move-to-source="removeRole"></CustomPickList>
        </div>
        <portal to="footer-actions">

          <FormActions>
            <template #left>
              <div v-if="user.id">
                <DangerButton v-if="$helper.hasAnyRole([$roles.ADMIN, $roles.HRADMIN, $roles.HRMANAGER])"
                              @click="confirmDelete"> {{ $t('delete') }}
                </DangerButton>
              </div>
            </template>
            <SecondaryButton v-if="user.id && !user.email_verified_at" @click="sendActivationMail">{{
                $t('Send activation mail')
              }}
            </SecondaryButton>
            <div
                v-if="user.id && $helper.hasAnyRole([$roles.ADMIN, $roles.HRADMIN, $roles.HRMANAGER, $roles.HREMPLOYEE])">
              <SecondaryButton v-if="!user.deleted_at" @click="confirmDeactivate">{{
                  $t('deactivate')
                }}
              </SecondaryButton>
              <SecondaryButton v-if="user.deleted_at" @click="confirmReactivate">{{ $t('activate') }}</SecondaryButton>
            </div>
            <PrimaryButton v-if="user.id" @click="saveUser">{{ $t('save') }}</PrimaryButton>
            <PrimaryButton v-if="!user.id" @click="confirmSendActivationMail">{{ $t('continue') }}</PrimaryButton>
          </FormActions>
        </portal>
      </div>


    </form>

    <div v-if="$route.params.id && this.userCompetences">
          <CompetenceGrid :competences="this.userCompetences"
                          :show-ability="true"
                          :show-claim="true"
                          :show-job-abilities="true"
                          :show-current-job="true"
          ></CompetenceGrid>
        </div>

    <Dialog :visible.sync="showConfirmDelete" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Confirm Delete {title}', {title: user.name}) }}
      </template>
      <div class="confirmation-content">
        {{ $t('Do you really want to delete user {title}?', {title: user.name}) }}
      </div>
      <template #footer>
        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmDelete">{{ $t('Cancel') }}</SecondaryButton>
          </template>
          <DangerButton @click="deleteUser">{{ $t('delete') }}</DangerButton>
        </FormActions>
      </template>
    </Dialog>
    <Dialog :visible.sync="showConfirmDeactivate" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Confirm Deactivate {title}', {title: user.name}) }}
      </template>
      <div class="confirmation-content">
        {{ $t('Do you really want to deactivate user {title}?', {title: user.name}) }}
      </div>
      <template #footer>
        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmDeactivate">{{ $t('Cancel') }}</SecondaryButton>
          </template>
          <PrimaryButton @click="deactivateUser">{{ $t('deactivate') }}</PrimaryButton>
        </FormActions>
      </template>
    </Dialog>
    <Dialog :visible.sync="showConfirmReactivate" :modal="true"
            :contentStyle="{overflow: 'visible'}">
      <template #header>
        {{ $t('Confirm activate {title}', {title: user.name}) }}
      </template>
      <div class="confirmation-content">
        {{ $t('Do you really want to activate user {title}? Please choose a job position', {title: user.name}) }}
        <div class="col-12 form-group">
          <label for="job-position">{{ $t('Job position') }}</label>
          <PickerInput id="job-position"
                       :title="$t('Free job positions')"
                       :placeholder="$t('Select job position')"
                       :selected="selectedJobPosition.title"
                       :show-clear="false"
                       ref="job-position-picker"
                       class="reactivate-picker">
            <TreeList :nodes="this.functionalGroupsWithFreejobPositions"
                      :show-sub-nodes="true"
                      column-field="name"
                      :custom-node-key="'jobPositions'"
                      @customNodeSelected="jobPositionSelected"
                      :node-selectable="false"
            ></TreeList>
          </PickerInput>
        </div>
      </div>
      <template #footer>
        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmReactivate">{{ $t('Cancel') }}</SecondaryButton>
          </template>
          <DisabledButton v-if="!selectedJobPosition.id">{{ $t('activate') }}</DisabledButton>
          <PrimaryButton v-if="selectedJobPosition.id" @click="reactivateUser">{{ $t('activate') }}</PrimaryButton>
        </FormActions>
      </template>
    </Dialog>
    <Dialog :visible.sync="showConfirmSendActivationMail" :style="{width: '350px'}" :modal="true"
            :contentStyle="{overflow: 'visible'}">
      <template #header>
        {{ $t('Send activation mail') }}
      </template>
      <div class="confirmation-content">
        {{ $t('Do you want to send an activation link?') }}
      </div>
      <template #footer>
        <FormActions class="m-1">
          <template #left>
            <SecondaryButton @click="saveUser">{{ $t('No') }}</SecondaryButton>
          </template>
          <PrimaryButton @click="saveUser(true)">{{ $t('Send activation mail') }}</PrimaryButton>
        </FormActions>
      </template>
    </Dialog>
  </div>

</template>

<script>
import {email, maxLength, numeric, required, requiredIf} from 'vuelidate/lib/validators';
import Dialog from 'primevue/dialog';
import PickerInput from "@/components/PickerInput";
import TreeList from "@/components/TreeList";
import CompetenceGrid from "@/components/CompetenceGrid";
import CustomPickList from "@/components/CustomPickList";
import DatePicker from "../../components/DatePicker.vue";

export default {
  name: 'users',
  data() {
    return {
      user: {
        id: null,
        language: 'de',
        deleted_at: null,
        email_sent_at: null,
      },
      showConfirmDelete: false,
      showConfirmDeactivate: false,
      showConfirmReactivate: false,
      showConfirmSendActivationMail: false,
      selectedJobPosition: {
        id: null
      },
      selectedHrGroup: {},
      selectedFutureJobPosition: {
        id: null
      },
      selectedLanguage: {label: 'Deutsch', code: 'de'},
      langs: this.$helper.getAvailableLanguages(),
      userCompetences: null,
      roles: null,
      functionalGroups: null,
      functionalGroupsWithFreejobPositions: null
    }
  },
  created() {
    if (this.$route.params.id) {
      this.getUser()
    }
    this.getFunctionalGroups()
    this.getFunctionalGroupsWithFreejobPositions()
    this.getHrGroups()
  },
  methods: {
    sendActivationMail() {
      if (this.user.id) {
        this.$http.get('send-activate/' + this.user.id)
            .then(() => {
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Activation mail sent'),
                life: 3000,
              });
              this.getUser()
              this.$store.dispatch('getHrGroups')
            })
            .catch((error) => {
              this.$helper.showErrorToast(error, this.$t('Error in sending activation mail'))
            });
      }
    },
    getUser() {
      if (this.$route.params.id) {
        this.$http.get('users/' + this.$route.params.id)
            .then((res) => {
              this.user = {...res.data}
              this.user.birth_date = new Date(res.data.birth_date)
              this.selectedJobPosition = {...res.data.job_position}
              this.selectedFutureJobPosition = {...res.data.future_job_position}
              this.selectedHrGroup = {...res.data.hr_groups_as_member[0]}
              if(this.user.deleted_at === null){
                this.$http.get('user-competences/' + this.user.id)
                    .then((res) => {
                      this.userCompetences = res.data
                  })
              }
              this.getRoles()
            })
            .catch((error) => {
              console.log("ERROR ", error)
              this.$helper.showErrorToast(error.this.$t('Error in getting user'))
              //this.$router.push({name: 'backend.users.user'})
            })
      }
    },
    getRoles() {
      this.$store.dispatch('getRoles').then(() => {
        this.roles = [this.$store.state.roles.map((role) => {
          role.name = this.$t(role.name)
          return role
        }).filter((role) => {
          return !this.user.roles.some((userRole) => {
            return role.id === userRole.id
          })
        }), this.user.roles.map((role) => {
          role.name = this.$t(role.name)
          return role
        })]
      })
    },
    getFunctionalGroups() {
      this.$store.dispatch('getFunctionalGroups').then((res) => {
        this.functionalGroups = res.data
      })
    },
    getFunctionalGroupsWithFreejobPositions() {
      this.$store.dispatch('getFunctionalGroupsWithFreeJobPositions').then((res) => {
        this.functionalGroupsWithFreejobPositions = res.data
      })
    },
    getHrGroups() {
      this.$store.dispatch('getHrGroups')
    },
    saveUser(sendActivationMail = false) {
      this.$v.$touch();
      if (!this.$route.params.id) {
        this.confirmSendActivationMail()
      }
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$toast.add({
          severity: 'error',
          summary: this.$t('The form has errors'),
          detail: this.$t('Please check the form again'),
          life: 3000,
        });
      } else {
        this.user.send_activation_mail = sendActivationMail
        this.user.job_position_id = this.selectedJobPosition.id
        if (this.selectedJobPosition.id) {
          this.user.future_job_position_id = this.selectedFutureJobPosition.id
        }
        this.user.hr_group_as_member_id = this.selectedHrGroup.id
        this.user.language = this.selectedLanguage.code
        if (this.$route.params.id) {
          this.$http.put('users/' + this.user.id, {...this.user})
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('User updated successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getHrGroups')
              })
              .catch((error) => {
                this.$helper.showErrorToast(error, this.$t('Error in updating'))
              });
        } else {
          this.$http.post('users', {...this.user})
              .then((res) => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('User added successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getHrGroups').then(() => {
                  this.$router.push({
                    name: 'backend.hr-groups.user',
                    params: {id: res.data.id},
                    hash: '#user-roles'
                  })
                })


              })
              .catch((error) => {
                this.$helper.showErrorToast(error, this.$t('Error in adding'))
              });
        }
      }
    },
    confirmDelete() {
      this.showConfirmDelete = !this.showConfirmDelete
    },
    confirmDeactivate() {
      this.showConfirmDeactivate = !this.showConfirmDeactivate
    },
    confirmReactivate() {
      this.showConfirmReactivate = !this.showConfirmReactivate
    },
    confirmSendActivationMail() {
      this.showConfirmSendActivationMail = !this.showConfirmSendActivationMail
    },
    deleteUser() {
      this.$http.delete('users/' + this.user.id)
          .then((res) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'success',
              summary: this.$t(res.data.message),
              life: 3000,
            });
            this.$router.push('/backend/hr-groups/user')
          })
          .catch((error) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'error',
              summary: this.$t(error.data.message),
              life: 3000,
            });
          });
    },
    deactivateUser() {
      this.$http.delete('users/' + this.user.id + '/deactivate')
          .then((res) => {
            this.showConfirmDeactivate = false;
            this.$toast.add({
              severity: 'success',
              summary: this.$t(res.data.message),
              life: 3000,
            });
            this.$store.dispatch('getHrGroups')
            this.getUser()
          })
          .catch((error) => {
            this.showConfirmDeactivate = false;
            this.$toast.add({
              severity: 'error',
              summary: this.$t(error.data.message),
              life: 3000,
            });
          });
    },
    reactivateUser() {
      if (this.selectedJobPosition.id) {

        this.$http.put('users/' + this.user.id + '/reactivate', {'job_position_id': this.selectedJobPosition.id})
            .then(() => {
              this.showConfirmReactivate = false;
              this.$toast.add({
                severity: 'success',
                summary: this.$t('User successfully activated'),
                life: 3000,
              });
              this.$store.dispatch('getHrGroups')
              this.getUser()
            })
            .catch((error) => {
              this.showConfirmReactivate = false;
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.data.message),
                life: 3000,
              });
            });
      }

    },
    jobPositionSelected(jobPosition) {
      this.selectedJobPosition = jobPosition
      this.$refs["job-position-picker"].closeDialog()
    },
    futureJobPositionSelected(jobPosition) {
      this.selectedFutureJobPosition = jobPosition
      this.$refs["future-job-position-picker"].closeDialog()
    },
    hrGroupSelected(hrGroup) {
      this.selectedHrGroup = hrGroup
      this.$refs["hr-group-picker"].closeDialog()
    },
    languageSelected(language) {
      this.selectedLanguage = language
      this.$refs["language-picker"].closeDialog()
    },
    addRole(role) {
      for (let item of role.items) {
        this.$http.post('user-role', {'userId': this.user.id, 'roleId': item.id})
            .then(() => {
              this.getUser()
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Role successfully added'),
                life: 3000,
              });
            })
            .catch((error) => {
              this.$helper.showErrorToast(error, this.$t('Error in adding'))
            });
      }
    },
    removeRole(role) {
      for (let item of role.items) {
        this.$http.delete('user-role/' + this.user.id + '/' + item.id)
            .then(() => {
              this.getUser()
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Role successfully removed'),
                life: 3000,
              });
            })
            .catch((error) => {
              this.$helper.showErrorToast(error, this.$t('Error in removing'))
            });
      }
    },
  },
  components: {
    DatePicker,
    Dialog,
    PickerInput,
    TreeList,
    CompetenceGrid,
    CustomPickList
  },
  validations: {
    user: {
      first_name: {
        required,
        maxLength: maxLength(255)
      },
      last_name: {
        required,
        maxLength: maxLength(255)
      },
      email: {
        required,
        email
      },
      birth_date: {
        required,
      },
      employee_number: {
        required,
        numeric
      },
    },
    selectedJobPosition: {
      title: {
        required: requiredIf(function () {
          return !this.user.deleted_at;
        })
      }
    },
    selectedHrGroup: {
      name: {
        required
      }
    },
  },
  computed: {
    hrGroups() {
      return this.$store.state.hrGroups
    }
  },
}
</script>