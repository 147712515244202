<template>
  <div class="d-flex flex-column w-100">
    <div class="user-wrapper">
      <UserImage :src="img" class="user-img-list"></UserImage>
      <div class="user-name">{{ name }}</div>
    </div>
  </div>
</template>
<script>


import UserImage from "../UserImage.vue";

export default {
  name: 'user-column',
  components: {UserImage},
  props: ['name', 'img']
}

</script>